import React from "react";
import { Box, Button, Card, Typography, TextField, Divider } from "@mui/material";
import { COLORS } from "../constants/Theme";
import Header from "./HomeHeader";

const HealthCheckupPackages = () => {
    return (
        <Box>
            <Header />
            {/* Header Section */}
            <Box p={2}>
                <Typography variant="h5" fontWeight="bold" sx={{ mb: 1, color: COLORS.primaryColor, mt: 1, fontFamily: 'Poppins' }}>
                    Health Checkup Packages
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 1, fontFamily: 'Poppins' }}>
                    Choose the right health screening package for your needs
                </Typography>

                {/* Search and Tabs Section */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 3,
                    }}
                >
                    <TextField
                        placeholder="Search packages..."
                        variant="outlined"
                        size="small"
                        fullWidth
                        // width="100%"
                        sx={{
                            backgroundColor: COLORS.secondaryColor,
                            borderRadius: 2,
                            maxWidth: '700px',
                            // "& .MuiOutlinedInput-root": {
                            //     borderColor: COLORS.primaryColor,
                            // },
                            "& .MuiInputLabel-root.Mui-focused": {
                                    color: COLORS.placeholderColor,
                                    fontFamily: "Poppins"// Label color when focused
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: COLORS.placeholderColor,
                                    fontFamily: "Poppins" // Label color when disabled
                                },
                                "& .MuiOutlinedInput-root": {
                                    fontFamily: "Poppins", // Apply font to input
                                    "& fieldset": {
                                        borderColor: 'transparent',
                                        boxShadow: 2
                                    },
                                    "&:hover fieldset": {
                                        borderColor: 'transparent',
                                        boxShadow: 2
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: 'transparent',
                                        boxShadow: 2
                                    },
                                },
                                "& .MuiInputBase-input": {
                                    fontFamily: "Poppins", // Ensure text inside input uses the font
                                },
                            }}
                        />                        
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: COLORS.primaryColor,
                            color: "#fff",
                            textTransform: "none",
                            fontFamily: 'Poppins',
                            px: 3,
                            "&:hover": {
                                backgroundColor: COLORS.primaryColor,
                            },
                        }}
                    >
                        All Packages
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            borderColor: COLORS.secondaryColor,
                            color: COLORS.textColor,
                            fontFamily: 'Poppins',
                            textTransform: "none",
                            px: 3,
                        }}
                    >
                        Recommended
                    </Button>
                </Box>

                {/* Recommended Package Card */}
                <Card
                    sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                        mb: 3,
                    }}
                >
                    {/* Recommended Tag */}
                    <Typography
                        variant="subtitle2"
                        sx={{ color: COLORS.primaryColor, mb: 1, fontWeight: "bold" }}
                    >
                        <Box component="span" sx={{ display: "inline-block", mr: 1 }}>
                            <span role="img" aria-label="shield">
                                🛡️
                            </span>
                        </Box>
                        Recommended Package
                    </Typography>

                    {/* Package Details */}
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mb: 1 }}
                    >
                        COMPREHENSIVE PREVENTIVE HEALTH SCREENING WOMEN ABOVE 65 YEARS
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ mb: 2 }}
                    >
                        Complete health screening designed specifically for senior women
                    </Typography>

                    <Divider sx={{ mb: 2 }} />

                    {/* Package Features */}
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
                        <Typography
                            variant="body1"
                            sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                            <span role="img" aria-label="clipboard">
                                📝
                            </span>
                            14 tests included
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                            <span role="img" aria-label="clock">
                                ⏱️
                            </span>
                            Duration: 2-3 hours
                        </Typography>
                    </Box>

                    {/* Test List */}
                    <Box sx={{ pl: 2 }}>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            ➤ Complete Blood Count
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            ➤ Diabetes Screening
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            ➤ Cardiac Risk Assessment
                        </Typography>
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default HealthCheckupPackages;
