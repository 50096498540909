import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Drawer, Button, TextField, Typography, FormControlLabel, CircularProgress, Box, IconButton, Avatar, } from '@mui/material';
import Modal from 'react-modal';
import DeviceDetector from 'device-detector-js';
import { verifyNumber } from '../utils/apiCalls';
import { loginResponse } from '../store/actions/authActions';
import TermsAndConditionsModal from './TermsConditionsModal';
import PrivacyPolicy from './PrivacyPolicy1';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import Images from '../constants/Images';
import { COLORS } from '../constants/Theme';
import { PhoneNumInputField } from './PhoneNumInputField';
import { auth_content } from '../constants/strings';
import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import OTPDrawer from './OtpDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { BASE_URL } from '../utils/constant';
import axios from 'axios';

const CustomCheckbox = styled(Checkbox)({
    color: COLORS.primaryColor,
    '&.Mui-checked': {
        color: COLORS.primaryColor,
    },
});

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 120,
    borderRadius: '30px',
    backgroundColor: COLORS.primaryColor,
    '&:disabled': {
        backgroundColor: '#FFF7FB', // or any other color you prefer
        color: theme.palette.text.disabled,
    },
}));

const ProfileImage = ({ open, onClose }) => {
    const [number, setNumber] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [checked, setChecked] = useState(false);
    const [checkBoxDisable, setCheckBoxDisable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [imageSource, setImageSource] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            const file = acceptedFiles[0];
            setUserImage(URL.createObjectURL(file));
            setImageSource(file);
        },
    });

    // Function to upload the image
    const handleSaveProfile = async () => {
        if (!imageSource) return;
        setImageUploading(true);

        const uniqueId = uuidv4();
        console.log(uniqueId);

        const formData = new FormData();
        formData.append('photo', imageSource, uniqueId);

        try {
            const token = localStorage.getItem('user_token'); // Get token from local storage
            const response = await axios.post(`${BASE_URL}/user/photo/upload/`, formData, {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Upload successful', response.data);
            // Redirect after upload or show a success message
        } catch (error) {
            console.error('Upload error', error);
        } finally {
            setImageUploading(false);
        }
    };

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleSkip = () => {
        // navigate('/next-page'); 
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box sx={{ width: { xs: 300, sm: 400, md: 500 }, padding: 3 }}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100px',
                        // backgroundColor: '#9c1d85', 
                        backgroundColor: '#FFEEF6',
                        clipPath: 'ellipse(50% 70% at 50% 0%)', // Creates the first layer (curve)
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                >
                    <Box
                        sx={{
                            // width: '100%',
                            // height: '100px',
                            // backgroundColor: '#9c1d85', // Lighter purple color for the inner curve
                            // clipPath: 'ellipse(50% 70% at 50% 0%)', // Creates the second layer (curve)
                            // position: 'absolute',
                            // top: '40px', // Slightly offset the second curve
                            // left: 0,
                            width: '100%',
                            height: '70px',
                            // backgroundColor: '#7a186c', // Dark purple color
                            backgroundColor: COLORS.primaryColor, // Dark purple color
                            clipPath: 'ellipse(50% 70% at 50% 0%)', // Creates the first layer (curve)
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    />
                </Box>
                {/* Close Button Icon */}
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '40px',
                        right: '20px',
                        color: COLORS.primaryColor,
                        backgroundColor: '#fff',
                        borderColor: COLORS.primaryColor,
                        zIndex: 1,
                        '&:hover': {
                            backgroundColor: 'rgba(122, 24, 108, 0.1)', // Light purple on hover
                        },
                        transition: 'background-color 0.3s ease', // Smooth transition effect
                    }}
                >
                    <CloseIcon sx={{ fontSize: '12px' }} />
                </IconButton>
                {/* </Box> */}
                <Box display="flex" justifyContent="center" mb={2} mt={7} sx={{ fontFamily: 'Poppins', fontSize: '32px', color: COLORS.primaryColor, fontWeight: 'bold' }}>
                    {/* <img src={Images.Logo_Hq} alt="Logo" style={{ maxWidth: '80%', height: 'auto' }} /> */}
                    Set Profile Photo
                </Box>
                <Box sx={{ my: 2 }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {userImage ? (
                        <Avatar
                            src={userImage}
                            sx={{ width: 100, height: 100, mx: 'auto', cursor: 'pointer' }}
                        />
                    ) : (
                        <Avatar
                            sx={{ width: 100, height: 100, mx: 'auto', cursor: 'pointer' }}
                        >
                            Upload
                        </Avatar>
                    )}
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveProfile}
                    disabled={imageUploading || !imageSource}
                    fullWidth
                >
                    {imageUploading ? <CircularProgress size={24} /> : 'Save Profile'}
                </Button>

                <Button
                    variant="text"
                    color="secondary"
                    onClick={handleSkip}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Skip for now
                </Button>
                {/* <Box display="flex" justifyContent="center" mt={5} sx={{}}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={verifyNumberFunc}
            disabled={number.length !== 10 || !checked || checkBoxDisable}
          >

            {loader ? <CircularProgress size={24} /> : 'Verify'}
          </StyledButton>
        </Box>
        <TermsAndConditionsModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        />
        <PrivacyPolicy
          isOpen={isModalOpen1}
          onRequestClose={() => setIsModalOpen1(false)}
        />*/}
            </Box>
            {/* <OTPDrawer open={isDrawerOpen} onClose={handleDrawerClose} />  */}
        </Drawer >
    );
};

export default ProfileImage;
