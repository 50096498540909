import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Header from './HomeHeader';
import { COLORS } from '../constants/Theme';
import { useHistory } from 'react-router-dom';
import { ticketListData } from '../utils/apiCalls';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { formatTimestampToDateTime } from '../utils/helpers';

const YourComponent = ({ records, handleOpen }) => {
  const [tickets, setTickets] = useState([]);
  const history = useHistory();
  const [ticketList, setTicketList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);
  const [page_size, setPage_size] = useState(10);
  const [onMomentScroll, setOnMomentScroll] = useState(false);
  const [next_page, setNext_page] = useState('');

  useEffect(() => {
    ticketListApiCall(page, page_size);
  }, [page]);

  const ticketListApiCall = async (currentPage, pageSize) => {
    setLoader(true);
    try {
      const response = await ticketListData(currentPage, pageSize);
      console.log(response, 'response');
      if (isResponseIsValid(response)) {
        console.log(response.data.data, 'response');

        if (currentPage == 1) {
          setTicketList(response.data.data);
          setNext_page(response.data.next_page);
        } else {
          setTicketList([...ticketList, ...response.data.data]);
          setNext_page(response.data.next_page);
        }
        setTimeout(() => {
          setLoader(false);
        }, 200);
      } else {
        setLoader(false);
        setTimeout(() => {
          if (response?.data) {
            snackBar(response?.data);
          } else {
            snackBar(Headers.apiError);
          }
        }, 400);
      }
    } catch (err) {
      setLoader(false);
      setTimeout(() => {
        snackBar(Headers.apiError);
      }, 400);
    }
  };

  const timestamp = "2023-07-19T14:21:46+0000";
  const formattedDateTime = formatTimestampToDateTime(timestamp);
  console.log(formattedDateTime); // Output: "07/19/2023 - 2:21 pm"

  return (
    <Box sx={{ padding: 0 }}>
      <Header />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mb: 3, backgroundColor: '#ffe6f2', height: '60px', p: 0, margin: 0, width: '100%', boxShadow: 1 }}>
        {/* <Avatar
          src={item.photo}
          alt="Doctor Image"
          sx={{ width: 50, height: 55, marginRight: 2, ml: 4, mt: 1, }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '16px' }}>{item.first_name}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(item.designation) ? item.designation.join(', ') : item.designation}</Typography>
        </Box>
        <Box sx={{ ml: 8 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{item.default_specialization}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography>
        </Box>
        <Box sx={{ ml: 12 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{item.languages ?
            (Array.isArray(item.languages) ? item.languages.join(', ') : item.languages) :
            'Tamil, English'
          }</Typography>
          {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
        {/* </Box> */}
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        {/* <ArrowBackIosIcon style={{ marginRight: '0.5rem', cursor: 'pointer' }} />
        <Typography variant="h6" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>My Account</Typography> */}
        <Button
          onClick={() => window.history.back()}
          sx={{
            marginTop: '20px', marginBottom: 1, marginLeft: '50px', fontSize: '16px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none',
            ':focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
            },
          }}
        >
          &lt; Tickets generated
        </Button>
      </Box>
      <Box textAlign="center" my={4}>
        {ticketList.length === 0 ? (
          <>
            <Typography variant="body2" fontFamily="Poppins" color={COLORS.textColor} gutterBottom>
              No tickets found
            </Typography>

          </>
        ) : (
          <>
            {ticketList.map((ticket, index) => (
              <Card variant="outlined" sx={{ marginBottom: '16px', borderRadius: '8px', boxShadow: 1, width: '70%', justifyContent: 'center', alignItems: 'center', ml: 15 }}>
                <CardContent>
                  <Grid container justifyContent="space-between" alignItems="left">
                    <Grid item alignItems="left">
                      <Typography variant="h6" sx={{ fontWeight: 'normal', fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '16px', textAlign: 'left' }}>
                        {ticket._ref}
                      </Typography>
                      <Typography variant="body2" fontFamily='Poppins' color={COLORS.placeholderColor} fontSize='14px' textAlign='left'>
                        {formatTimestampToDateTime(ticket.created_on)}
                      </Typography>
                      <Typography variant="body2" fontFamily='Poppins' color={COLORS.placeholderColor} fontSize='14px' textAlign='left'>
                        {/* {ticket.message} */}
                        Patient requested an appointment Booking
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" align="right" fontFamily='Poppins' color={COLORS.placeholderColor} fontSize='14px'>
                        ID: {ticket.ticket_id}
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{
                          color: '#E81219',
                          borderColor: 'transparent',
                          marginTop: '8px',
                          padding: '4px 8px',
                          textTransform: 'none',
                          backgroundColor: '#FDE4E5',
                          fontFamily: 'Poppins',
                          borderRadius: '10px',
                          fontSize: '12px'
                        }}
                      >
                        {ticket.status}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default YourComponent;
