import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  IconButton,
  Button,
  Avatar,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField, Tabs, Tab
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { allowNumOnly, isResponseIsValid, snackBar } from '../utils/helpers';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import Header from './HomeHeader';
import { COLORS } from '../constants/Theme';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getProfileApi } from '../utils/apiCalls';
import LoginDrawer from './LoginDrawer';
import {
  bookingDoctorDetails,
  doctorDetails,
  slotSelectionApi,
  slotSelectionApiBook,
  slotsListApi,
  unitsListApi,
} from '../utils/apiCalls';
import {
  consultMode,
  doctorDetailsItem,
  slotAddress,
  slotAmount,
  slotDetails,
  slotStartTime,
} from '../store/actions/bookingActions';
import moment from 'moment';
// Define styles using makeStyles
import { makeStyles } from '@mui/styles';
import { amount } from '../store/actions/types';
import GTranslateIcon from '@mui/icons-material/GTranslate';

const styles = {
  customButton: {
    width: '20px',
  },
};

const AppointmentBook = () => {
  // const classes = useStyles();
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState(generateDateRange(new Date()));
  const history = useHistory();
  const location = useLocation();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [formattedDate, setFormattedDate] = useState('');
  const [formattedDate1, setFormattedDate1] = useState('');
  const [doctorSlot, setDoctorSlot] = useState([]);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [data, setData] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [slots, setSlots] = useState([]);
  const [slot_range, setSlot_range] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [slot_rangeVideo, setSlot_rangeVideo] = useState('');
  const [popupLoading, setPopupLoading] = useState(false);
  const [accordion, setAccordion] = useState('open');
  const [offlineSlotDetails, setOfflineSlotDetails] = useState({});
  const [onlineSlotDetails, setOnlineSlotDetails] = useState({});
  const [onSelectedDate, setOnSelectedDate] = useState(new Date());  
  const [videoCallApiCalled, setVideoCallApiCalled] = useState(false);
  const [video_retry, setVideo_retry] = useState(false);
  const [consulting_video_slot, setConsulting_video_slot] = useState([]);
  const [consulting_slot, setConsulting_slot] = useState([]);
  const [noFessPopup, setNoFessPopup] = useState(false);
  
  const [slotItemData, setSlotItemData] = useState({});
  const [dataList, setDataList] = useState({});
  const [page, setPage] = useState(1);
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [retry, setRetry] = useState(false);

  const [doctorId, setDoctorId] = useState('');

  const queryParams = new URLSearchParams(location.search);
  const item = JSON.parse(queryParams.get('item'));
  const dataItem = JSON.parse(queryParams.get('dataItem'));
  const pay_at_hospital = queryParams.get('pay_at_hospital');
  const doctor_id = queryParams.get('doctor_id');
  console.log(item);

  const itemFromBookingDetail = item;

//   const doctor_id = item ? item._id.$oid : '';
  const [slotTime, setSlotTime] = useState('');
  const [rupees, setRupees] = useState(itemFromBookingDetail.amount);
  const [selectedDate, setSelectedDate] = useState(new Date(itemFromBookingDetail.slot_date));
  const [onSelectedVideoDate, setOnSelectedVideoDate] = useState(new Date(item.slot_date));
  const [timeSlot, setTimeSlot] = useState([]);
  const [doctorFee, setDoctorFee] = useState([]);
  const [fees, setFees] = useState('');
  const [slotType, setSlotType] = useState('Walk In');
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    // console.log('vvvvvvv', newValue);
    // if (newValue == 1) {
    //   getVideoSlotsApi(selectedDate);
    //   setPage('0');
    // }
    if (itemFromBookingDetail.booking_type === 'Offline') {
        if (page !== '0') {
          setIsActive1(true);
          setIsActive2(false);
          setPage('0');
          // setRupees('0.00');
          if (consulting_video_slot.length !== 0) {
            consulting_video_slot.forEach((item1, i) => {
              item1.is_selected = false;
            });
            setConsulting_video_slot([...consulting_video_slot]);
          } else {
          }
          console.log('consulting_slot length', consulting_slot.length);
        }
      }
      else if(itemFromBookingDetail.booking_type === 'Online') {
        if (page !== '1') {
          setIsActive1(false);
          setIsActive2(true);
          setPage('1');
          // setRupees('0.00');
          if (consulting_slot.length !== 0) {
            consulting_slot.forEach((item1, i) => {
              item1.doctor_slots_data[0].timings.forEach((item2, i) => {
                item2.is_selected = false;
              });
            });
            setConsulting_slot([...consulting_slot]);
          }
        }
      }
  };

  useEffect(() => {
    
    const mm = moment('06:05:44', 'HH:mm:ss').format('HH:mm');
    console.log('mm', mm);
    // getWeeks(new Date(itemFromBookingDetail.slot_date));
    // getVideoWeeks(new Date(itemFromBookingDetail.slot_date));
    if (itemFromBookingDetail.booking_type === 'Offline') {
      setPage('0');
      setIsActive1(true);
      setIsActive2(false);
      const rp = itemFromBookingDetail;
      setDataList(rp);
      getSlotsApi(new Date(itemFromBookingDetail.slot_date));
      // onCallApi(new Date(item.slot_date));
    } else {
      setPage('1');
      setIsActive1(false);
      setIsActive2(true);
      const rp = itemFromBookingDetail;
      setDataList(rp);
      getVideoSlotsApi(new Date(itemFromBookingDetail.slot_date));
      // onCallVideoApi(new Date(item.slot_date));
    }
  }, []);

//   useEffect(() => {
//     getUnitsListApi(selectedDate);
//     const formatted = formatDate(selectedDate);
//     const formatted1 = formatDate1(selectedDate);
//     setFormattedDate(formatted);
//     setFormattedDate1(formatted1);
//   }, [selectedDate]);

  useEffect(() => {
    onCallProfileApi();
    // upcomingEventsApiCall();
  }, []);

  const onCallProfileApi = async () => {
    const response = await getProfileApi();
    console.log(response);
    if (isResponseIsValid(response)) {
      // dispatch(profileImageSaved(response.data.photo));
      setData(response.data);
      setName(response.data.name);
    }
  };

  // useEffect(() => {
  //   const initializeData = async () => {
  //     await fetchLocations();
  //     // await fetchSlots(selectedUnit, formattedDate1, doctor_id, slotType);
  //     // if (slotTime) {
  //     await fetchDoctorFee(selectedUnit, doctor_id);
  //     // }
  //   };

  //   if (formattedDate && formattedDate1) {
  //     initializeData();
  //   }
  // }, [selectedUnit, formattedDate, doctor_id, formattedDate1, slotTime, slotType]);

  

  const getSlotsApi = async (date) => {
    setPopupLoading(true);
    const sd = moment(date).format('YYYY-MM-DD');
    setOnSelectedDate(sd);
    try {
      const body = {
        doctor_id: doctor_id,
        slot_type: 'Offline',
        date: sd,
      };
      console.log('Request body', body);
      const response = await slotsListApi(body);
      console.log('SLOT LIST RESPONSE', JSON.stringify(response));
      if (isResponseIsValid(response)) {
        setRetry(false);
        console.log('SLOT LIST', JSON.stringify(response.data));
        const slot_list_data = response.data;
        setSlot_range(slot_list_data.data.consult_range);
        setSlots(slot_list_data.data.timings);     

        let slot_details = {
          unit_id: itemFromBookingDetail.unit_details._id.$oid,
          slot_type: "Offline",
          slot_date: sd
        }

        setOfflineSlotDetails(slot_details);

        dispatch(slotDetails(slot_details));

        setTimeout(() => {
          setLoadingData(false);
          setPopupLoading(false);
        }, 200);
      } else{
      setSlots([]);
      setRetry(true);
      snackBar(`We're experiencing high traffic! Please try again shortly`);
        setTimeout(() => {
          setLoadingData(false);
          setPopupLoading(false);
        }, 200);
      }
    } catch (error) {
      setTimeout(() => {
        setLoadingData(false);
        setPopupLoading(false);
      }, 200);
      console.error(error);
    }
  };

  const getVideoSlotsApi = async (date) => {
    setPopupLoading(true);
    const sd = moment(date).format('YYYY-MM-DD');
    setOnSelectedVideoDate(sd);
    try {
      const body = {
        doctor_id: doctor_id,
        slot_type: 'Online',
        date: sd,
      };
      console.log('Request body video', body);
      const response = await slotsListApi(body);
      console.log('VIDEO SLOT RESPONSE', JSON.stringify(response));
      if (isResponseIsValid(response)) {
        setVideo_retry(false);
        const video_slot_list_data = response.data;
        setSlot_rangeVideo(video_slot_list_data.data.consult_range);
        console.log('video_slot_list_data.data.timings', video_slot_list_data.data.timings);
        setConsulting_video_slot(video_slot_list_data.data.timings);

        let slot_details = {
          unit_id: itemFromBookingDetail.unit_details._id.$oid,
          slot_type: "Online",
          slot_date: sd
        }

        setOnlineSlotDetails(slot_details);

        dispatch(slotDetails(slot_details));
        setTimeout(() => {
          setLoadingData(false);
          setPopupLoading(false);
        }, 200);
      }else{
        setVideo_retry(true);
        setSlot_rangeVideo([]);
        setConsulting_video_slot([]);
        setTimeout(() => {
          setLoadingData(false);
          setPopupLoading(false);
        }, 200);
      }
    } catch (error) {
      setTimeout(() => {
        setLoadingData(false);
        setPopupLoading(false);
      }, 200);
      console.error(error);
    }
  };

  
  function generateDateRange(startDate) {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  const handleDateChange = (direction) => {
    const newStartDate = new Date(dateRange[0]);
    newStartDate.setDate(newStartDate.getDate() + (direction === 'next' ? 1 : -1));
    setDateRange(generateDateRange(newStartDate));
  };

  const handleDateChange1 = (direction) => {
    const newStartDate = new Date(dateRange[0]);
    newStartDate.setDate(newStartDate.getDate() + (direction === 'next' ? 1 : -1));
    setDateRange(generateDateRange(newStartDate));
  };

  const handleCalendarOpen = () => {
    setOpen(true);
  };

  const handleCalendarClose = () => {
    setOpen(false);
  };

  const handleCalendarDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    // Update formattedDate1 based on the new date
    const newFormattedDate1 = formatDate1(newDate);
    setFormattedDate1(newFormattedDate1);

    getSlotsApi(newDate, true, unitsList[0].doctor_id);
    setDateRange(generateDateRange(newDate));
    setOpen(false);
  };

  const handleCalendarDateChange1 = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    
    const newFormattedDate1 = formatDate1(newDate);
    setFormattedDate1(newFormattedDate1);

    getVideoSlotsApi(newDate);
    setDateRange(generateDateRange(newDate));
    setOpen(false);
  };

  const handleNext = () => {
    console.log(JSON.stringify(dataList), 'DOCTOR DETAILS');
    console.log(rupees, 'RUPEES');
    dispatch(doctorDetailsItem(dataList));
    dispatch(slotAmount(rupees));
    dispatch(consultMode(page === 1 ? 'Offline' : 'Online'));
    const slot_address = {
      address: itemFromBookingDetail.unit_details.address,
      name: itemFromBookingDetail.unit_details.name,
      unit_id: itemFromBookingDetail.unit_id,
    }
    dispatch(slotAddress(slot_address));
    const slot_info_1 = {
      slot_date: moment(selectedDate).format('YYYY-MM-DD'),
      name: itemFromBookingDetail.unit_details.name,
      address: itemFromBookingDetail.unit_details.address,
    };
    const slot_info_2 = {
      slot_date: moment(selectedDate).format('YYYY-MM-DD'),
      name: itemFromBookingDetail.unit_details.name,
      address: itemFromBookingDetail.unit_details.address,
    };
    // navigation.navigate('RescheduleConfirm', {
    //   item: dataItem,
    //   slotInfo: page == 1 ? slot_info_1 : slot_info_2,
    //   pay_at_hospital: pay_at_hospital,
    //   date_info: dataList,
    // });

    console.log(page,page == 0 ? slot_info_1 : slot_info_2, 'SLOT DATEEEE');
      const queryParams = new URLSearchParams({
        // dropdownOptions: JSON.stringify(dropdownOptions),
        item: JSON.stringify(dataItem),
        slotInfo: page == 0 ? slot_info_1 : slot_info_2,
        date_info: JSON.stringify(dataList),
        pay_at_hospital: pay_at_hospital,
      }).toString();

      history.push(`/rescheduleconfirm?${queryParams}`);
    
  };

  // const handletimeSlot = (slot) => {
  //   setSelectedSlot(slot);
  //   const slotType = doctorSlot.length > 0 ? doctorSlot[0].SlotType : null;
  //   console.log('typeeee', slotType)
  //   const fee = doctorFee.find(f => f.ConsultType === slotType);
  //   setFees(fee ? fee.ConsultPrice : 'N/A');
  // };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
  };

  const formatDate1 = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
  };

  const handleUnitChange = (unit) => {
    // Handle unit change
  };

  const handleDate = async (date) => {
    // setIsDrawerOpen(true);
    setSelectedDate(date);

    const newFormattedDate1 = formatDate1(date);
    setFormattedDate1(newFormattedDate1);

    getSlotsApi(date);
  };

  const handleDate1 = async (date) => {
    // setIsDrawerOpen(true);
    setSelectedDate(date);

    const newFormattedDate1 = formatDate1(date);
    setFormattedDate1(newFormattedDate1);

    getVideoSlotsApi(date);
  };

  const handletimeSlot = (item, index) => {
    setSelectedSlot(item);
    
      onSelectSlot(item, index);
    
  };

  const handletimeSlotVideo = (item, index) => {
    setSelectedSlot(item);
    
      onSelectVideoSlot(item, index);
  };

  const onSelectVideoSlot = (item, index) => {
    consulting_video_slot.forEach((item1, i) => {
        if (i === index) {
          item1.is_selected = true;
          item1.is_booked = true;
          const startTime = item1.time;
          // const startTime = timeRange.split(' - ')[0];
          dispatch(slotStartTime(startTime));
        } else {
          item1.is_selected = false;
          item1.is_booked = false;
        }
      });
  
      setConsulting_video_slot([...consulting_video_slot]);
      setRupees(itemFromBookingDetail.amount);
  };

  const onSelectSlot = (item, index, slotIndex) => {
    console.log('onPressSlotTag args', item, index);
    slots.forEach((item1, i) => {
      if (i === index) {
        item1.is_selected = true;
        dispatch(slotStartTime(item1.time));
      } else {
        item1.is_selected = false;
      }
    })

    setSlots([...slots]);
    // let details = { unit_id: unitsList[slotIndex].unit_id };
    // dispatch(slotDetails(details));
    // dispatch(slotDetails({unit_id: unitsList[slotIndex].unit_id}));
    setRupees(itemFromBookingDetail.amount);
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  
  return (
    <Container style={{ padding: 0, margin: 0, backgroundColor: '#fff' }}>
      <Header
        dropdownOptions={dropdownOptions}
        onUnitChange={handleUnitChange}
        slotType={slotType}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: 1 }}>
        <Avatar
          src={item.photo}
          alt="Doctor Image"
          sx={{ width: 50, height: 55, marginRight: 2, ml: 4, mt: 1, }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '16px' }}>{item.first_name}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(item.designation) ? item.designation.join(', ') : item.designation}</Typography>
        </Box>
        <Box sx={{ ml: 8 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{item.default_specialization}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography>
        </Box>
        <Box sx={{ ml: 12 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{item.languages ?
            (Array.isArray(item.languages) ? item.languages.join(', ') : item.languages) :
            'Tamil, English'
          }</Typography>
          {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
        </Box>
      </Box>

      <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, backgroundColor: '#FFFFFF', borderRadius: 1, width: { xs: '100%', md: '60%' }, mx: 'auto', mt: 2 }}>
        {/* <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: '16px', color: '#9C27B0' }}>Hospital visit</Typography> */}
        {/* <Box display="flex" alignItems="left" justifyContent='flex-end' mb={2} mr={50}>
        <ArrowBackIosIcon sx={{ color: '#962067', cursor: 'pointer' }} />
        <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>Select date & time</Typography>
      </Box> */}
        <Tabs value={selectedTab}
          onChange={handleTabChange}
          centered
          sx={{
            textTransform: 'none',
            fontFamily: 'Poppins',
            '& .MuiTabs-indicator': {
              color: COLORS.textColor,
              backgroundColor: COLORS.primaryColor, // Set the underline color
            },
          }}
        >
          <Tab label="Hospital visit"
            icon={<LocalHospitalIcon />}
            iconPosition="start"
            sx={{
              textTransform: 'none',
              fontFamily: 'Poppins',
              color: selectedTab === 0 ? COLORS.whiteColor : COLORS.textColor,
              backgroundColor: selectedTab === 0 ? COLORS.primaryColor : 'transparent',
              width: '50%',
              minHeight: '10%',
              height: '10%',
              borderBottom: 'none',// Adjust width to fit the container
              '&:hover': {
                backgroundColor: COLORS.primaryColor,
                color: '#ffffff',
                borderColor: COLORS.primaryColor
              },
              '&.Mui-selected': {
                backgroundColor: COLORS.primaryColor,
                color: '#ffffff',
                borderBottom: 'none',// Ensures white text when active (selected)
              },
              // '&:active': {
              //   backgroundColor: COLORS.primaryColor,
              //   color: selectedTab === 0 ? '#ffffff' : COLORS.textColor,
              //   borderColor: COLORS.primaryColor
              // },
              '&:active': {
                backgroundColor: COLORS.primaryColor,
                color: '#ffffff', // Ensures white text on active state
                borderColor: COLORS.primaryColor,
                borderBottom: 'none',
              },
            }}
          />
          <Tab label="Video consultation"
            icon={<VideoCallIcon />}
            iconPosition="start"
            sx={{
              textTransform: 'none',
              fontFamily: 'Poppins',
              color: selectedTab === 1 ? '#ffffff' : COLORS.textColor,
              backgroundColor: selectedTab === 1 ? COLORS.primaryColor : 'transparent',
              width: '50%', // Adjust width to fit the container
              minHeight: '10%',
              height: '10%',
              '&:hover': {
                backgroundColor: COLORS.primaryColor,
                color: '#ffffff',
                borderColor: COLORS.primaryColor
              },
              '&.Mui-selected': {
                backgroundColor: COLORS.primaryColor,
                color: '#ffffff',
                borderBottom: 'none',// Ensures white text when active (selected)
              },

            }}
          />
        </Tabs>
        {selectedTab === 0 && (
          <Box>
            <Button
              onClick={() => window.history.back()}
              sx={{
                marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                },
                // ':hover': {
                //   backgroundColor: COLORS.primaryColor,
                // },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                },
              }}
            >
              &lt; Select date & time
            </Button>
            <Box display="flex" justifyContent="space-between" p={1}>
              <Typography sx={{ fontFamily: 'Poppins', color: '#962067', ml: 10, mb: 2 }}>{formattedDate}</Typography>
              <CalendarTodayIcon sx={{ color: '#962067', cursor: 'pointer', mr: 10, mb: 2 }} onClick={handleCalendarOpen} />
            </Box>

            <Box sx={{ display: 'flex', padding: 2 }}>
              <IconButton sx={{
                color: COLORS.primaryColor, ml: 6, mb: 1,
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                },
                // ':hover': {
                //   backgroundColor: COLORS.primaryColor,
                // },
                ':active': {
                  boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                },
              }} onClick={() => handleDateChange('prev')} >
                <ArrowBackIosIcon />
              </IconButton>
              <Grid container justifyContent="center" alignItems="center" spacing={1} mb={3} >
                {dateRange.map(date => (
                  <Grid item key={date.toDateString()}>
                    <Button
                      variant="outlined"
                      className="custom-button"
                      sx={{
                        ...styles.customButton,
                        borderColor: '#939598',
                        color: '#939598',
                        // width: '40px !important',
                        height: 60,
                        fontFamily: 'Poppins',
                        '&:hover': {
                          backgroundColor: '#ffe6f2',
                          color: '#939598',
                          borderColor: '#ffe6f2',
                        },
                        ...(selectedDate.toDateString() === date.toDateString() && {
                          backgroundColor: '#ffe6f2',
                          color: '#939598',
                          borderColor: '#ffe6f2',
                        }),
                        ':focus': {
                          outline: 'none',
                          boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                        },
                        // ':hover': {
                        //   backgroundColor: COLORS.primaryColor,
                        // },
                        ':active': {
                          boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                        },
                      }}
                      onClick={() => handleDate(date)}
                    >
                      {date.toDateString().slice(0, 3)} {date.getDate()}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <IconButton sx={{
                color: COLORS.primaryColor, mr: 4, mb: 2, fontSize: '10px',
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                },
                // ':hover': {
                //   backgroundColor: COLORS.primaryColor,
                // },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                },
              }} onClick={() => handleDateChange('next')}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>

            {/* {slots == '' ? ( */}

           
              <Box >
                <Paper sx={{ p: 2, backgroundColor: '#FFFFFF', borderRadius: 1, mb: 2, ml: 13, justifyContent: 'center', alignItems: 'center', width: '70%' }}>
                  {/* <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, mb: 0.5 }}>{item.name}</Typography>
                  <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: 12, mb: 0.5 }}>{unitAddress}</Typography> */}


                  {slots.length === 0 ? (
                    <Typography sx={{ fontFamily: 'Poppins', color: '#939598', textAlign: 'center', fontSize: 14, my: 2, mb: 2, p: 2 }}>
                      The doctor is not available for visit on <span style={{ color: COLORS.primaryColor }}>{formattedDate}</span>. Please select another date or check another locality.
                    </Typography>
                  ) : (
                    <Box>
                      {/* <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, mb: 0.5 }}>{selectedUnit}</Typography>
                <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: 12, mb: 0.5 }}>{unitAddress}</Typography>
                <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: 12, mb: 0.5 }}>Available slots</Typography> */}
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 1, borderRadius: '8px' }}>
                        {slots.map((slot, index) => (
                          <Button
                            key={slot.time}
                            variant="outlined"
                            sx={{
                              borderColor: '#939598',
                              borderRadius: '8px',
                              color: '#939598',
                              m: 0.5,
                              '&:hover': {
                                backgroundColor: '#ffe6f2',
                                color: '#939598',
                                borderColor: '#ffe6f2',
                              },
                              ...(selectedSlot === slot.time && {
                                backgroundColor: '#ffe6f2',
                                color: '#939598',
                                borderColor: '#ffe6f2',
                              }),
                              ':focus': {
                                outline: 'none',
                                boxShadow: `0 0 0 2px transparent`, // Keeps focus ring transparent
                              },
                              ':active, &.active': {
                                backgroundColor: '#ffe6f2',
                                color: '#939598',
                                borderColor: '#ffe6f2',
                              },
                            }}
                            onClick={() => handletimeSlot(slot.time, index)}
                          >
                            {slot.time}
                          </Button>
                        ))}
                      </Box>
                    </Box>
                  )}

                </Paper>

              </Box>
            

            <Box display="flex" justifyContent="center" >
              <Box display="flex" justifyContent='space-between' flexDirection='row' backgroundColor='#DCDCDC33' mt={2} border='0px solid #00000029' borderRadius={1} width={{ xs: '40%', md: '40%' }} boxShadow='0px 2px 4px rgba(0, 0.1, 0.1, 0.1) #00000029'>
                <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>To pay:</Typography>
                <Typography sx={{ fontFamily: 'Poppins', mr: 4 }}>{rupees}</Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent='center'>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#962067',
                  color: '#fff',
                  p: 1,
                  borderRadius: 8,
                  justifyContent: 'center',
                  textTransform: 'none',
                  mt: 2,
                  width: { xs: '30%', md: '30%' },
                  '&:hover': {
                    backgroundColor: '#962067',
                    borderColor: '#ffe6f2',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                  },
                }}
                onClick={handleNext}
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}

        {selectedTab === 1 && (
          <Box>
            <Button
              onClick={() => window.history.back()}
              sx={{
                marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                },
                // ':hover': {
                //   backgroundColor: COLORS.primaryColor,
                // },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                },
              }}
            >
              &lt; Select date & time
            </Button>
            <Box display="flex" justifyContent="space-between" p={1}>
              <Typography sx={{ fontFamily: 'Poppins', color: '#962067', ml: 10, mb: 2 }}>{formattedDate}</Typography>
              <CalendarTodayIcon sx={{ color: '#962067', cursor: 'pointer', mr: 10, mb: 2 }} onClick={handleCalendarOpen} />
            </Box>

            <Box sx={{ display: 'flex', padding: 2 }}>
              <IconButton sx={{
                color: COLORS.primaryColor, ml: 6, mb: 1,
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                },
                // ':hover': {
                //   backgroundColor: COLORS.primaryColor,
                // },
                ':active': {
                  boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                },
              }} onClick={() => handleDateChange1('prev')} >
                <ArrowBackIosIcon />
              </IconButton>
              <Grid container justifyContent="center" alignItems="center" spacing={1} mb={3} >
                {dateRange.map(date => (
                  <Grid item key={date.toDateString()}>
                    <Button
                      variant="outlined"
                      className="custom-button"
                      sx={{
                        ...styles.customButton,
                        borderColor: '#939598',
                        color: '#939598',
                        // width: '40px !important',
                        height: 60,
                        fontFamily: 'Poppins',
                        '&:hover': {
                          backgroundColor: '#ffe6f2',
                          color: '#939598',
                          borderColor: '#ffe6f2',
                        },
                        ...(selectedDate.toDateString() === date.toDateString() && {
                          backgroundColor: '#ffe6f2',
                          color: '#939598',
                          borderColor: '#ffe6f2',
                        }),
                        ':focus': {
                          outline: 'none',
                          boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                        },
                        // ':hover': {
                        //   backgroundColor: COLORS.primaryColor,
                        // },
                        ':active': {
                          boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                        },
                      }}
                      onClick={() => handleDate1(date)}
                    >
                      {date.toDateString().slice(0, 3)} {date.getDate()}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <IconButton sx={{
                color: COLORS.primaryColor, mr: 4, mb: 2, fontSize: '10px',
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                },
                // ':hover': {
                //   backgroundColor: COLORS.primaryColor,
                // },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                },
              }} onClick={() => handleDateChange1('next')}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>

            {/* {slots == '' ? ( */}

            {/* {unitsList.map((item, slotIndex) => (
              
                <Paper sx={{ p: 2, backgroundColor: '#FFFFFF', borderRadius: 1, mb: 2, ml: 13, justifyContent: 'center', alignItems: 'center', width: '70%' }}>
                  <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, mb: 0.5 }}>{item.name}</Typography>
                  <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: 12, mb: 0.5 }}>{unitAddress}</Typography> */}


                  {consulting_video_slot.length === 0 ? (
                    <Box >
                    <Typography sx={{ fontFamily: 'Poppins', color: '#939598', textAlign: 'center', fontSize: 14, my: 2, mb: 2, p: 2 }}>
                      The doctor is not available for visit on <span style={{ color: COLORS.primaryColor }}>{formattedDate}</span>. Please select another date or check another locality.
                    </Typography>
                    </Box>
                  ) : (
                    <Box>
                      {/* <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, mb: 0.5 }}>{selectedUnit}</Typography>
                <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: 12, mb: 0.5 }}>{unitAddress}</Typography>
                <Typography sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: 12, mb: 0.5 }}>Available slots</Typography> */}
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 1, borderRadius: '8px' }}>
                        {consulting_video_slot.map((slot, index) => (
                          <Button
                            key={slot.time}
                            variant="outlined"
                            // sx={{
                            //   borderColor: '#939598',
                            //   borderRadius: '8px',
                            //   color: '#939598',
                            //   m: 0.5,
                            //   '&:hover': {
                            //     backgroundColor: '#ffe6f2',
                            //     color: '#939598',
                            //     borderColor: '#ffe6f2',
                            //   },
                            //   ...(selectedSlot === slot.time && {
                            //     backgroundColor: '#ffe6f2',
                            //     color: '#939598',
                            //     borderColor: '#ffe6f2',
                            //   }),
                            //   ':focus': {
                            //     outline: 'none',
                            //     boxShadow: `0 0 0 2px transparent`,
                            //   },
                            //   ':active': {
                            //     boxShadow: `0 0 0 2px transparent`,
                            //   },
                            // }}
                            sx={{
                              borderColor: '#939598',
                              borderRadius: '8px',
                              color: '#939598',
                              m: 0.5,
                              '&:hover': {
                                backgroundColor: '#ffe6f2',
                                color: '#939598',
                                borderColor: '#ffe6f2',
                              },
                              ...(selectedSlot === slot.time && {
                                backgroundColor: '#ffe6f2',
                                color: '#939598',
                                borderColor: '#ffe6f2',
                              }),
                              ':focus': {
                                outline: 'none',
                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                              },
                              // ':hover': {
                              //   backgroundColor: COLORS.primaryColor,
                              // },
                              ':active': {
                                // boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                                backgroundColor: '#ffe6f2',
                                color: '#939598',
                                borderColor: '#ffe6f2',
                              },
                            }}
                            onClick={() => handletimeSlotVideo(slot.time, index)}
                          >
                            {slot.time}
                          </Button>
                        ))}
                      </Box>
                    </Box>
                    
                  )}

                {/* </Paper> */}

              
            

            <Box display="flex" justifyContent="center" >
              <Box display="flex" justifyContent='space-between' flexDirection='row' backgroundColor='#DCDCDC33' mt={2} border='0px solid #00000029' borderRadius={1} width={{ xs: '40%', md: '40%' }} boxShadow='0px 2px 4px rgba(0, 0.1, 0.1, 0.1) #00000029'>
                <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>To pay:</Typography>
                <Typography sx={{ fontFamily: 'Poppins', mr: 4 }}>{rupees}</Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent='center'>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#962067',
                  color: '#fff',
                  p: 1,
                  borderRadius: 8,
                  justifyContent: 'center',
                  textTransform: 'none',
                  mt: 2,
                  width: { xs: '30%', md: '30%' },
                  '&:hover': {
                    backgroundColor: '#962067',
                    borderColor: '#ffe6f2',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                  },
                }}
                onClick={handleNext}
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}
      </Paper>


      <Box sx={{ display: 'flex', width: '100%', height: 80, backgroundColor: '#962067', borderRadius: '10px 10px 0px 0px', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
        <Typography variant="body2" sx={{ color: '#fff' }}>
          © 2024 Patient Appointment Booking. All rights reserved.
        </Typography>
      </Box>
      <LoginDrawer open={isDrawerOpen} onClose={handleDrawerClose} />
      <Dialog open={open} onClose={handleCalendarClose}>
        <DialogTitle>Select Date</DialogTitle>
        <DialogContent>
          <TextField
            type="date"
            fullWidth
            onChange={handleCalendarDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCalendarClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AppointmentBook;