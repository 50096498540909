import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Card, Avatar, Divider, Modal, ListItem, List } from "@mui/material";
import Header from "./HomeHeader";
import { COLORS } from "../constants/Theme";
import AppointmentModal from "./AppointmentBook";
import { IoLanguageOutline } from "react-icons/io5";
import { LuGraduationCap } from "react-icons/lu";
import { useLocation, useHistory } from "react-router-dom";
import { isResponseIsValid, snackBar } from '../utils/helpers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  upcomingEvents,
  updateBooking,
  specializationListWithUnitID,
  doctorsListInConsultation,
} from '../utils/apiCalls';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import GTranslateIcon from '@mui/icons-material/GTranslate';

const doctorsData = [
  {
    name: "Dr. Sathish P",
    qualifications: "MS, DNB, MCh",
    specialty: "Surgical Gastroenterology",
    experience: "10+ years experience",
    languages: "Tamil, English",
    rating: 4.8,
    nextAvailable: "Today, 2:00 PM",
    education: ["MBBS - Madurai Medical College", "MS - Madras Medical College"],
    specialization:
      "Expertise in minimally invasive surgical procedures for gastrointestinal disorders.",
    fee: "800"
  },
  {
    name: "Dr. Mala Balagopal R",
    qualifications: "MBBS, DO",
    specialty: "Ophthalmology",
    experience: "10+ years experience",
    languages: "Tamil, English",
    rating: 4.8,
    nextAvailable: "Today, 2:00 PM",
    education: ["MBBS - Madurai Medical College", "D.O - RIOGOH, Madras Medical College"],
    specialization:
      "Presented papers on contact lens fitting and conducted instruction courses at various conferences.",
    fee: "800"
  },
];

const DoctorCard = ({ doctor, unitAddress, unitId, setDoctor, setOpenPopup, openPopup, onOpenAppointmentModal, onClick }) => (
  <Card
    sx={{
      p: 2,
      mb: 3,
      boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
      borderRadius: "12px",
      cursor: "pointer"
    }}
    onClick={onClick}
  >
    {/* Top Section */}
    <Box display="flex" alignItems="flex-start" gap={2}>
      {/* Image */}
      <Avatar
        variant="square"
        sx={{
          width: 120,
          height: 140,
          borderRadius: "8px",
          backgroundColor: "#f8f8f8",
        }}
        src={doctor.photo}
      >

      </Avatar>
      {/* Doctor Details */}
      <Box sx={{ display: 'flex', flexDirection: "column" }}>
        <Typography variant="h7" sx={{ fontWeight: "bold", color: COLORS.textColor, fontFamily: 'Poppins' }}>
          {doctor.first_name}
        </Typography>
        <Typography variant="body4" sx={{ color: COLORS.textColor, fontFamily: "Poppins", fontSize: "14px" }}>
          {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
        </Typography>
        <Box
          sx={{
            display: "inline-block",
            backgroundColor: "#FFEEF6",
            color: COLORS.primaryColor,
            px: 2,
            py: 0.5,
            borderRadius: "16px",
            mt: 1,
          }}
        >
          {doctor.default_specialization}
        </Box>
        <Box sx={{
          // gap: 5, // Adds spacing between child elements
        }}>
          <Typography
            variant="body2"
            color={COLORS.placeholderColor}
            sx={{ mt: 1 }}
          >
            Experience: {doctor.experience}
          </Typography>
          <Typography
            variant="body2"
            color={COLORS.placeholderColor}
            sx={{ mt: 1, justifyContent: "flex-end" }}
          >
            {/* Languages: {doctor.languages} */}
            Language: {doctor.languages ?
              (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
              'Tamil, English'
            }
          </Typography>
        </Box>
      </Box>
    </Box>

    {/* Divider */}
    <Divider sx={{ my: 2 }} />

    {/* Buttons Section */}
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="body2" color={COLORS.placeholderColor} sx={{ fontFamily: "Poppins" }}>
        Next Available:
      </Typography>
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          onClick={onOpenAppointmentModal}
          sx={{
            backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
            color: "#fff",
            textTransform: "none",
            fontFamily: "Poppins",
            '&:hover': {
              backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
            },
            ':focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
            },
            ':active': {
              outline: 'none',
              boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
            },
          }}
        >
          {doctor.request_only === 0 ? 'Book Now' : 'Request'}
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: COLORS.primaryColor,
            borderColor: COLORS.primaryColor,
            textTransform: "none",
            fontFamily: "Poppins",
            "&:hover": { backgroundColor: "#f3e5f5", borderColor: COLORS.primaryColor, outline: "none" },
            "&:active": { backgroundColor: "#f3e5f5", borderColor: COLORS.primaryColor, outline: "none" },
            "&:focus": { backgroundColor: "#f3e5f5", borderColor: COLORS.primaryColor, outline: "none" },
          }}
        >
          View Profile
        </Button>
      </Box>
    </Box>
  </Card>
);

const DoctorDetailsModal = ({ open, onCloseModal, onOpenAppointmentModal, doctor, selectedUnit }) => {
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);

  if (!doctor) return null;

  const handleOpenAppointmentModal = () => {
    setOpenAppointmentModal(true);
    
  };

  const handleCloseAppointmentModal = () => {
    setOpenAppointmentModal(false);
  };

  return (
    <Modal open={open} onClose={onCloseModal} sx={{
      display: "flex",
      alignItems: "center", // Centers vertically
      justifyContent: "center", // Centers horizontally
    }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          p: 3,
          borderRadius: "12px",
          // boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
          width: "100%", // Adjust width as needed
          maxWidth: "700px", // Limit max width
          maxHeight: "90vh",
          // maxWidth: "600px",
          mx: "auto",
          mt: "3%",
          position: "relative",
          fontFamily: "Poppins",
          overflowY: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: COLORS.primaryColor,
            mb: 2,
            fontFamily: "Poppins",
          }}
        >
          About the Doctor
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar
            variant="square"
            sx={{
              width: 120,
              height: 140,
              borderRadius: "8px",
              backgroundColor: "#f8f8f8",
            }}
            src={doctor.photo}
          >
          </Avatar>
          <Box>
            <Typography
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                color: COLORS.textColor,
              }}
            >
              {doctor.first_name}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  // fontWeight: "bold",
                  color: COLORS.textColor,
                }}
              >
                {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
              </Typography>
              <Typography

                // variant="body2"
                // sx={{
                //   color: COLORS.primaryColor,
                //   // mt: 1,
                //   fontFamily: "Poppins",
                // }}
                sx={{ display: "list-item", fontFamily: "Poppins", color: COLORS.primaryColor, fontSize: '14px', ml: 3 }}
              >
                {doctor.default_specialization}
                {/* </ListItem> */}
              </Typography>
              {/* </List> */}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
              <IoLanguageOutline color={COLORS.placeholderColor} sx={{ fontSize: 40, marginRight: '10px', color: COLORS.placeholderColor }} />
              <Typography
                variant="body2"
                sx={{
                  ml: 1,
                  fontFamily: "Poppins",
                  color: COLORS.textColor,
                }}
              >
                {/* {doctor.languages} */}
                Language: {doctor.languages ?
                  (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                  'Tamil, English'
                }
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LuGraduationCap size={26} color={COLORS.primaryColor} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h7"
              sx={{
                fontWeight: "bold",
                mb: 1,
                ml: 1,
                fontFamily: "Poppins",
                color: COLORS.textColor,
              }}
            >
              Education
            </Typography>
            {doctor.designation ? (
              doctor.designation.map((edu, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{
                  fontFamily: "Poppins",
                  color: COLORS.textColor,
                  fontSize: "14px",
                  ml: 1
                }}
              >
                {edu}
              </Typography>
            )) ) : (
              ''
            )}
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography
          variant="h7"
          sx={{
            fontWeight: "bold",
            mb: 1,
            fontFamily: "Poppins",
            color: COLORS.textColor,
          }}
        >
          Specialization & Experience
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: COLORS.textColor,
          }}
        >
          {doctor.description}
        </Typography>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={onOpenAppointmentModal}
            sx={{
              backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
              textTransform: "none",
              fontFamily: "Poppins",
              '&:hover': {
                backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':active': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
            }}
          >
            {doctor.request_only === 0 ? 'Book Now' : 'Request'}
          </Button>
        </Box>

        {/* Appointment Modal */}
        {/* {openAppointmentModal && (
          <AppointmentModal
            open={openAppointmentModal}
            onCloseAppointment={handleCloseAppointmentModal}
            doctor={doctor}
            selectedUnit={selectedUnit}
          />
        )} */}
      </Box>
    </Modal>
  );
};

const DoctorsList = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [doctorsDetails, setDoctorsDetails] = useState([]);
  const [page_size, setPage_size] = useState(80);
  const [page, setPage] = useState(1);
  const [next_page, setNext_page] = useState(null);
  const [doctorListCount, setDoctorListCount] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  // const displayedDoctors = showAll1 ? doctorsDetails : doctorsDetails.slice(0, 4);
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [doctor, setDoctor] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const selectedUnit = queryParams.get('selectedUnit');
  const setSelectedUnit = queryParams.get('setSelectedUnit');
  const unitAddres = queryParams.get('unitAddress');
  const unitId = queryParams.get('unitid');
  const city = queryParams.get('city');
  const [unitAddress, setUnitAddress] = useState(unitAddres);
  const [isDoctorDetailsModalOpen, setDoctorDetailsModalOpen] = useState(false);
  const [isAppointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const doctors = JSON.parse(queryParams.get('doctors'));
  // const displayedDoctors = doctors.slice(0, 4);
  const specialtyId = queryParams.get('spId');
  const spec_name = queryParams.get('specName');
  console.log('uuuu', specialtyId);
  useEffect(() => {
    const initializeData = async () => {

      specialityDoctorList();
      // }
    };

    initializeData();
  }, []);

  const specialityDoctorList = async () => {
    try {
      const data = [(
        unitId,
        city,
        page,
        page_size,
        search,
        specialtyId,
        spec_name
      )];
      console.log('aaaaaaa', data);
      const doctorsResponse = await doctorsListInConsultation(
        unitId,
        city,
        1,
        5,
        search,
        specialtyId,
        spec_name,
      );

      if (isResponseIsValid(doctorsResponse)) {
        if (page == 1) {
          setDoctorListCount(doctorsResponse.data.total_count);
          setDoctorsDetails(doctorsResponse.data.data);

        }
        else {
          setDoctorsDetails(prevDoctorsDetails => [
            ...prevDoctorsDetails,
            ...doctorsResponse.data.data,
          ]);
          console.log('dcrrrrr', doctorsDetails);
        }
      } else {
        if (doctorsResponse?.data) {
          snackBar(doctorsResponse?.data);
        } else {
          snackBar(Headers.apiError);
        }
      }
      setTimeout(() => {
        setLoader(false);
        setInitialLoader(false);
      }, 400);
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
        setInitialLoader(false);
        snackBar(Headers.apiError);
      }, 500);

    }
  };

  const handleCardClick = (doctor) => {
    setSelectedDoctor(doctor);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedDoctor(null);
    setIsModalOpen(false);
  };

  const handleOpenDoctorDetailsModal = (doctor) => {
    setSelectedDoctor(doctor);
    setDoctorDetailsModalOpen(true);
    setAppointmentModalOpen(false); // Ensure the other modal is closed
  };

  const handleCloseDoctorDetailsModal = () => {
    setDoctorDetailsModalOpen(false);
  };

  const handleOpenAppointmentModal = () => {
    setAppointmentModalOpen(true);
    setDoctorDetailsModalOpen(false); // Close DoctorDetailsModal when AppointmentModal opens
  };

  const handleCloseAppointmentModal = () => {
    setAppointmentModalOpen(false);
    setIsDrawerOpen(true);
  };
  const handleDrawerOpen=() => {
    setIsDrawerOpen(true);
    setAppointmentModalOpen(false);
  };
  const handleCloseDrawer=() => {
    // setIsDrawerOpen(false);
    // setAppointmentModalOpen(false);
  };

  return (
    <Box>
      <Header />
      <Box sx={{ p: { xs: 2, md: 4 }, maxWidth: "800px", mx: "auto" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1, fontFamily: "Poppins", color: COLORS.primaryColor }}>
          Our Doctors
        </Typography>
        <Typography variant="body1" color={COLORS.textColor} sx={{ mb: 3, fontFamily: 'Poppins' }}>
          Find and book appointments with our specialists
        </Typography>
        <Box sx={{ cursor: "pointer", }}>
          {doctorsDetails.map((doctor, index) => (
            <DoctorCard key={index} doctor={doctor} onOpenAppointmentModal={handleOpenAppointmentModal} onClick={() => handleOpenDoctorDetailsModal(doctor)} />
          ))}
        </Box>
      </Box>
      <DoctorDetailsModal
        open={isDoctorDetailsModalOpen}
        onCloseModal={handleCloseDoctorDetailsModal}
        onOpenAppointmentModal={handleOpenAppointmentModal}
        doctor={selectedDoctor}
        selectedUnit={selectedUnit}
        unitAddres={unitAddres}
        unitId={unitId}
      />
      {isAppointmentModalOpen && (
          <AppointmentModal
            open={isAppointmentModalOpen}
            onOpenAppointmentModal={handleOpenAppointmentModal}
            onCloseAppointment={handleCloseAppointmentModal}
            onDrawerOpen={handleDrawerOpen}            
            doctor={selectedDoctor}
            selectedUnit={selectedUnit}
          />
        )}
    </Box>
  );
};
export default DoctorsList;
