import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Badge,
    Box,
    Avatar,
    Menu,
    MenuItem,
    Button, Modal
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import Images from "../constants/Images";
import { COLORS } from "../constants/Theme";
import { FaStethoscope } from "react-icons/fa";
import { GrNotes } from "react-icons/gr";
import BiotechIcon from '@mui/icons-material/Biotech';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { profileImageSaved } from '../store/actions/authActions';
import { getProfileApi } from '../utils/apiCalls';
import { logoutApi } from '../utils/apiCalls';
import { isResponseIsValid } from '../utils/helpers';
import { callNumber, removeItemValue, snackBar } from '../utils/helpers';
import { LogoutCall } from '../store/actions/authActions';
import LoginDrawer from "./LoginDrawer";

const Header = ({ dropdownOptions, selectedUnit, unitAddress, unitid, city, setSelectedUnit, user }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [notificationCount, setNotificationCount] = useState(3);
    const history = useHistory();
    const location = useLocation();
    const [name, setName] = useState('');
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        onCallProfileApi();
    }, []);

    const onLogoutApiCall = async () => {
        setLoader(true);
        try {
            const response = await logoutApi();
            console.log(response, 'response');
            if (isResponseIsValid(response)) {
                setLoader(false);
                // const pusherUnsubscribe = await pusher.unsubscribe({ channelName: 'Kauvery' }).then(
                //   (res) => {
                //     console.log('unsubscribed', res);
                //   }
                // ).catch((err) => {
                //   console.log(err);
                // })
                setTimeout(() => {
                    removeItemValue('User_Data');
                    dispatch(LogoutCall());
                    history.push('/');
                    // Auth();
                }, 200);
            } else {
                setLoader(false);
                setTimeout(() => {
                    if (response?.data?.message) {
                        // snackBar(JSON.stringify(response?.data?.message));
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setLoader(false);
            setTimeout(() => {
                snackBar(JSON.stringify(err));
            }, 400);
        }
    };

    const onCallProfileApi = async () => {
        const response = await getProfileApi();
        if (isResponseIsValid(response)) {
            dispatch(profileImageSaved(response.data.photo));
            setName(response.data.name);
        }
    };

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClick = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorE2(null);
        // localStorage.removeItem('selectedUnit'); // Clear selectedUnit from localStorage
        // setSelectedUnit('');
    };

    const handleSignout = () => {
        setAnchorE2(null);
        // localStorage.removeItem('selectedUnit');
        // setSelectedUnit('');
        setOpen(true);
    };

    const handleProfile = () => {
        history.push('/profile');
    };
    const openDrawer = () => {
        setIsDrawerOpen(true);
    };
    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleBookinglist = () => {
        const queryParams = new URLSearchParams({
            dropdownOptions: dropdownOptions,
            selectedUnit: selectedUnit,
            unitAddress: unitAddress,
            unitid: unitid,
            city: city
        }).toString();

        return `/specialitydoctor?${queryParams}`;
    };

    const handleNo = () => {
        setOpen(false);
    };
    const handleYes = () => {
        setOpen(false);
        onLogoutApiCall();
        history.push('/'); // Redirects to the home page
        window.location.reload(); // Forces a page reload
    };

    const handleEmergencyOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleEmergencyClose = () => {
        setAnchorEl(null);
    };

    const handleMenu1 = () => {
        history.push('/');
    };

    const handleMenu2 = () => {
        history.push('/profile');
    };

    const handleMenu3 = () => {
        history.push('/mhclist');
    };

    const handleMenu4 = () => {
        history.push('/profile');
    };

    const isHomeActive = location.pathname.endsWith('/');
    const isConsultActive = location.pathname.startsWith('/specialitydoctor') || location.pathname.startsWith('/appointmentbook') || location.pathname.startsWith('/selectpatient') || location.pathname.startsWith('/request') || location.pathname.startsWith('/doctors');
    const isMhcActive = location.pathname.startsWith('/mhclist') || location.pathname.startsWith('/mhc') || location.pathname.startsWith('/mhcbooking');
    const isRecordActive = location.pathname.startsWith('/records') || location.pathname.startsWith('/appointmentbooking') || location.pathname.startsWith('/selectpatient');

    return (
        <>
            {/* Logo and Emergency Bar */}
            <AppBar position="static" sx={{ bgcolor: "white", color: "black" }}>
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                    }}
                >
                    {/* Logo and Title */}
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={Images.yearsLogo}
                            alt="Kauvery Kare Logo"
                            style={{ width: "40%", height: "40px" }}
                        />
                        <Typography
                            variant="h7"
                            sx={{
                                ml: 2,
                                fontWeight: "bold",
                                fontSize: "18px",
                                color: COLORS.primaryColor,
                                fontFamily: "Poppins",
                            }}
                        >
                            Kauvery Kare
                        </Typography>
                    </Box>

                    {/* Emergency Dropdown */}
                    <Box>
                        <Button
                            onClick={handleEmergencyOpen}
                            endIcon={<ExpandMoreIcon />}
                            sx={{
                                color: "#d32f2f",
                                textTransform: "none",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                backgroundColor: "#fff",
                                ':focus': {
                                    outline: 'none',
                                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                },
                                ':hover': {
                                    backgroundColor: 'transparent',
                                },
                                ':active': {
                                    boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                                },
                            }}
                        >
                            Emergency? Call or visit our 24/7 Emergency Department
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleEmergencyClose}
                            sx={{
                                mt: "1rem",
                            }}
                        >
                            <MenuItem
                                sx={{
                                    color: COLORS.primaryColor,
                                    fontFamily: 'Poppins',
                                    ':hover': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#fff'
                                    },
                                }}>
                                Branch 1: 123-456-7890
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    color: COLORS.primaryColor,
                                    fontFamily: 'Poppins',
                                    ':hover': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#fff'
                                    },
                                }}>
                                Branch 2: 987-654-3210
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    color: COLORS.primaryColor,
                                    fontFamily: 'Poppins',
                                    ':hover': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#fff'
                                    },
                                }}>
                                Branch 3: 555-123-4567
                            </MenuItem>
                        </Menu>
                    </Box>

                    {/* Notification and User Section */}
                    <Box sx={{ flexDirection: 'column' }}>
                        <IconButton
                            sx={{
                                color: COLORS.primaryColor,
                                ':focus': {
                                    outline: 'none',
                                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                },
                                ':hover': {
                                    backgroundColor: '#fff',
                                },
                                ':active': {
                                    boxShadow: `0 0 0 2px #fff`, // Keeps the focus ring on active state
                                },
                            }}>
                            <Badge
                                badgeContent={notificationCount}
                                color="error"
                                invisible={notificationCount === 0}
                            >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        {name ? (
                            <>
                                <IconButton
                                    sx={{
                                        fontFamily: 'Poppins',
                                        fontSize: '15px',
                                        color: COLORS.primaryColor,
                                        ':focus': {
                                            outline: 'none',
                                            boxShadow: '0 0 0 2px transparent',
                                        },
                                        ':hover': {
                                            backgroundColor: '#fff',
                                        },
                                        ':active': {
                                            boxShadow: '0 0 0 2px #fff',
                                        },
                                    }}
                                    onClick={handleClick}
                                >
                                    {user?.image ? (
                                        <Avatar src={user.image} alt="User" />
                                    ) : (
                                        <AccountCircle />
                                    )}
                                    {name}
                                </IconButton>
                                <Menu
                                    anchorEl={anchorE2}
                                    open={Boolean(anchorE2)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                                    <MenuItem onClick={handleSignout}>Sign out</MenuItem>
                                </Menu>

                            </>
                        ) : (
                            <IconButton
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '15px',
                                    color: COLORS.primaryColor,
                                    ':focus': {
                                        outline: 'none',
                                        boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                    },
                                    ':hover': {
                                        backgroundColor: '#fff',
                                    },
                                    ':active': {
                                        boxShadow: `0 0 0 2px #fff`, // Keeps the focus ring on active state
                                    },
                                }}
                                onClick={openDrawer}>
                                {user?.image ? (
                                    <Avatar src={user.image} alt="User" />
                                ) : (
                                    <AccountCircle />
                                )}
                                Login
                            </IconButton>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Menu Bar */}
            <Toolbar
                sx={{
                    bgcolor: "#fff",
                    justifyContent: "center",
                    padding: "4px 0",
                    borderBottom: "1px solid #ddd",
                    boxShadow: 1
                }}
            >
                {/* Home */}
                <Box onClick={() => handleMenu1()} sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 3 }}>
                    <HomeIcon sx={{ mr: 1, color: COLORS.primaryColor, }} />
                    <Typography
                        sx={{
                            color: COLORS.primaryColor,
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            textDecoration: isHomeActive ? "underline" : '',
                            textDecorationColor: isHomeActive ? COLORS.primaryColor : '',
                            textUnderlineOffset: "22px",
                            '&:hover': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:active': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:focus': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                        }}
                    >
                        Home
                    </Typography>
                </Box>

                {/* Services */}
                <Box onClick={() => handleMenu2()} sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 3 }}>
                    <FaStethoscope style={{ marginRight: '10px', color: COLORS.primaryColor }} />
                    <Typography
                        sx={{
                            color: COLORS.primaryColor,
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            textDecoration: isConsultActive ? "underline" : '',
                            textDecorationColor: isConsultActive ? COLORS.primaryColor : '',
                            textUnderlineOffset: "22px",
                            '&:hover': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:active': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:focus': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                        }}
                    >
                        Consult
                    </Typography>
                </Box>

                {/* Contact Us */}
                <Box onClick={() => handleMenu3()} sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 3 }}>
                    <BiotechIcon sx={{ mr: 1, color: COLORS.primaryColor }} />
                    <Typography
                        sx={{
                            color: COLORS.primaryColor,
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            textDecoration: isMhcActive ? "underline" : '',
                            textDecorationColor: isMhcActive ? COLORS.primaryColor : '',
                            textUnderlineOffset: "22px",
                            '&:hover': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:active': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:focus': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                        }}
                    >
                        MHC
                    </Typography>
                </Box>

                {/* About Us */}
                <Box onClick={() => handleMenu4()} sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 3 }}>
                    <GrNotes style={{ marginRight: '10px', color: COLORS.primaryColor }} />
                    <Typography
                        sx={{
                            color: COLORS.primaryColor,
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            textDecoration: isRecordActive ? "underline" : '',
                            textDecorationColor: isRecordActive ? COLORS.primaryColor : '',
                            textUnderlineOffset: "22px",
                            '&:hover': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:active': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                            '&:focus': {
                                textDecoration: "underline",
                                textDecorationColor: COLORS.primaryColor,
                                textUnderlineOffset: "22px",
                            },
                        }}
                    >
                        Records
                    </Typography>
                </Box>
                <LoginDrawer isOpen={isDrawerOpen} closeDrawer={closeDrawer} />
                {/* Help */}
                {/* <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr:3 }}>
                    <HelpIcon sx={{ mr: 1, color: COLORS.primaryColor }} />
                    <Typography
                        sx={{
                            color: COLORS.primaryColor,
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                        }}
                    >
                        Help
                    </Typography>
                </Box> */}
                <Modal open={open} onClose={handleNo}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 0,
                        // display: 'flex',
                        // flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: '#ffffff', mb: 2 }}>

                            {/* <IconButton onClick={handleCloseDelete} sx={{ alignSelf: 'center', mt: 0, mb: 0, ml: 23, mt: 2, backgroundColor: 'red', justifyContent:'center' }}>
                            <CloseIcon />
                        </IconButton> */}

                        </Box>
                        {/* <Typography variant="h6" sx={{ mt: 2, ml: 2, mt: 2, textAlign: 'center' }} fontFamily='Poppins' color={COLORS.textColor} gutterBottom></Typography> */}
                        {/* <Typography variant="body2" color={COLORS.textColor} fontSize='14px' fontFamily='Poppins'>Delete</Typography> */}
                        <Typography variant="body2" color={COLORS.textColor} fontSize='12px' sx={{ mt: 2, ml: 2, mt: 2, textAlign: 'center', mb: 2 }} fontFamily='Poppins'>Are you sure you want to sign out?</Typography>
                        {/* <StyledTextField
                        label="File Name"
                        value={rename_file}
                        // onChange={(e) => validateName(e.target.value)}
                        onChange={(e) => setRenameFile(e.target.value)}
                        variant="outlined"
                        fullWidth margin="normal"
                        required
                    /> */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button variant="outlined"
                                sx={{
                                    marginTop: '10px',
                                    mb: 2,
                                    ml: 2,
                                    mr: 2,
                                    borderRadius: '20px',
                                    backgroundColor: COLORS.whiteColor,
                                    borderColor: '#962067', // Assuming you want the border to match the primary color
                                    color: COLORS.blackColor, // Text color
                                    // fontSize: '16px', // Change to desired font size
                                    fontFamily: 'Poppins', // Change to desired font weight
                                    textTransform: 'none',
                                    // boxShadow: '0px 2px 4px #962067',                                
                                    '&:hover': {
                                        backgroundColor: '#962067',
                                        // color: '#939598',
                                        // borderColor: '#ffe6f2'
                                    },
                                }}
                                onClick={handleNo}
                            >
                                No
                            </Button>
                            <Button variant="contained"
                                sx={{
                                    marginTop: '10px',
                                    mb: 2,
                                    // ml: 2,
                                    mr: 3,
                                    borderRadius: '20px',
                                    backgroundColor: '#962067',
                                    borderColor: '#962067', // Assuming you want the border to match the primary color
                                    color: COLORS.whiteColor, // Text color
                                    // fontSize: '16px', // Change to desired font size
                                    fontFamily: 'Poppins', // Change to desired font weight
                                    textTransform: 'none',
                                    boxShadow: '0px 2px 4px #962067',
                                    '&:hover': {
                                        backgroundColor: '#962067',
                                        // color: '#939598',
                                        borderColor: '#ffe6f2'
                                    },
                                }}
                                onClick={() => handleYes()}
                            >
                                Yes
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Toolbar>
        </>
    );
};

export default Header;
