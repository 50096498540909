import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  IconButton,
  Button,
  Avatar,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material';
import {
  bookingInitialize,
  createBooking,
  createBookingPayAtHospital,
  createWebHooks,
} from '../utils/apiCalls';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Header from './HomeHeader';
import { COLORS } from '../constants/Theme';
import { useHistory, useLocation } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { familyMembersList, requestNow } from '../utils/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { patientDetails, patient_Id, patient_Name, patient_Notes } from '../store/actions/bookingActions';
import { UploadDuplicateBookingList, UploadFileBookingList, emptyBookingFileUploadedFile, removeBookingFileUploadedFile } from '../store/actions/uploadBookingActions';
import { KauvaryFileUpload } from '../utils/KauveryFileUpload';
import { EventEmitter } from 'events';
import { getSecondaryProfileID, getUserInformation, setPayCheckoutSuccess, setWebHookCheckout } from '../utils/LocalStorage';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { Base64 } from 'js-base64';
import { currentProfileRawData, familyReloadData } from '../store/actions/homeActions';
import { emptyBookingUploadRecord, emptyUploadRecord, myUploadSelectedClear, myUploadBookingSelectedList, onMyUploadBookingSelection } from '../store/actions/recordActions';
import axios from 'axios';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { formatDateTime } from '../utils/helpers';
import CancelIcon from '@mui/icons-material/Cancel';
import { LiaFileAlt } from "react-icons/lia";

const StyledTextField = styled(TextField)({
  // marginTop: '20px',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
  '& .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for label
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for placeholder
  },
  mb: 1
});

const StyledSelect = styled((props) => <TextField select {...props} />)({
  marginTop: '20px',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
  '& .MuiInputBase-root': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for label
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiSelect-icon': {
    color: COLORS.primaryColor, // Custom color for dropdown icon
  },
  '& .Mui-focused .MuiInputLabel-root': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .Mui-focused .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .Mui-focused .MuiInputBase-root': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for placeholder
  },
});

const eventEmitter = new EventEmitter();

const SelectPatient = () => {
  // const classes = useStyles;
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedSlot, setSelectedSlot] = useState(null);
  const [open1, setOpen] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCheckoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState(generateDateRange(new Date()));
  // const [dropdownOptions, setDropdownOptions] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [relationData, setRelationData] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);
  const [userToken, setUserToken] = useState('');
  const [nameErrorState, SetNameErrorState] = useState(false);
  const [notesErrorState, SetNotesErrorState] = useState(false);
  const [name, setName] = useState('');
  const [nameErrorText, SetNameErrorText] = useState('');
  const [signUpState, setSignUpState] = useState(false);
  const [relationValue, setRelationValue] = useState('');
  const [notes, setNotes] = useState('');
  const [slotid, setSlotid] = useState(1687304);
  const [bookingErrorContent, setBookingErrorContent] = useState('');
  const [uhidList, setUhidList] = useState([]);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [isPatientNameValid, setIsPatientNameValid] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);

  const [isFormFilled, setIsFormFilled] = useState(false);
  const [formData, setFormData] = useState({
    relationValue: '',
    notes: '',
    uploadedFile: null,
    amount: 0, // Assuming amount is part of the form
  });

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [patientId, setPaitentId] = useState('');
  const unitName = useSelector(
    state => state?.authReducer?.unitName,
  );

  const slotDetails = useSelector(
    // Slot details
    state => state?.bookingReducer?.slotDetails,
  );

  const slotStartTime = useSelector(
    // Slot Start time
    state => state?.bookingReducer?.slotStartTime,
  );

  const slotAddress = useSelector(
    // Slot address
    state => state?.bookingReducer?.slotAddress,
  );

  const amount = useSelector(
    // Amount
    state => state?.bookingReducer?.amount,
  )

  const patientDetails = useSelector(
    // Patient details
    state => state?.bookingReducer?.patientDetails,
  );

  const doctorDetails = useSelector(
    // Doctor details
    state => state?.bookingReducer?.doctorDetails,
  );

  const profile_information = useSelector(
    state => state?.homeReducer?.profile_info,
  );

  const currentUser = useSelector(
    state => state?.homeReducer?.currentProfileName,
  );

  const uploadedFileList = useSelector(
    state => state?.uploadBookingReducer?.file_uploaded_list,
  );

  const currentProfileData = useSelector(
    state => state?.homeReducer?.currentProfileRawData,
  );

  const queryParams = new URLSearchParams(location.search);
  const selectedUnit = queryParams.get('selectedUnit');
  const fees = queryParams.get('fees');
  const unitid = queryParams.get('unitid');
  const time = queryParams.get('time');
  const item = JSON.parse(queryParams.get('item'));
  const data = JSON.parse(queryParams.get('data'));
  const doctorSlot = JSON.parse(queryParams.get('doctorSlot'));
  const dropdownOptions = JSON.parse(queryParams.get('dropdownOptions'));
  const unitAddress = queryParams.get('unitAddress');
  const date = queryParams.get('date');
  const page = queryParams.get('page');
  const [bookingErrorPopup, setBookingErrorPopup] = useState(false);
  // const [bookingErrorContent, setBookingErrorContent] = useState('');

  const [checkoutPressed, setCheckoutPressed] = useState(false);
  console.log('cccccccc', item);

  console.log('adrrr', slotAddress);
  console.log('detailsssssssss', slotDetails);
  console.log('timeeeee', slotStartTime);

  useEffect(() => {
    if (relationData.length > 0) {
      setRelationValue(relationData[0].name);
      dispatch(patient_Name(relationData[0].name));
      dispatch(patient_Id(relationData[0].app_profile_id));
    }
  }, [relationData]);

  useEffect(() => {
    const familyMembersSelectPatientListener = eventEmitter.addListener('familyMembersSelectPatient', (event) => {
      familyMembersApiCall();
      console.log(event, "Event");
      // if (event.isShowPopup) {
      //   setProfileCreatedPopup(event.isShowPopup);
      // }
      if (event.isName) {
        setName(event.isName);
      }
      getUserInformation('User_Data').then(res => {
        let response = JSON.parse(res);
        setUserToken(response.token);
      });
      familyMembersApiCall();
    });

    const sectionListReloadListener = eventEmitter.addListener('SectionListReload', (event) => {
      setTimeout(() => {
        setReloadKey(reloadKey + 1);
      }, 1000);
    });

    getUserInformation('User_Data').then(res => {
      let response = JSON.parse(res);
      setUserToken(response.token);
    });

    familyMembersApiCall();

    return () => {
      // familyMembersSelectPatientListener.remove();
      // sectionListReloadListener.remove();
    };
  }, [reloadKey]);


  useEffect(() => {
    dispatch(myUploadSelectedClear());
    dispatch(emptyBookingFileUploadedFile(''));
    dispatch(emptyBookingUploadRecord([]));
    // if (mhc == true) {
    //   getSecondaryProfileID('Secondary_Profile_ID')
    //     .then(res => {
    //       let json = JSON.parse(res);
    //       const relations = currentProfileData;
    //       relations.forEach(item => {
    //         if (item.app_profile_id === json) {

    //           dispatch(patient_Id(item.app_profile_id));
    //           setPaitentId(item.app_profile_id)
    //         }
    //       });
    //     })
    // }
  }, [])

  const handleRelationChange = (event) => {
    setRelationValue(event.target.value);
    setIsPatientNameValid(true);
  };

  const familyMembersApiCall = async () => {

    try {
      const response = await familyMembersList();
      if (isResponseIsValid(response)) {
        console.log(JSON.stringify(response.data.data));
        setRelationData(response.data.data);
        dispatch(currentProfileRawData(response.data.data));
        setTimeout(() => {

        }, 400);
      } else {
        setTimeout(() => {
          snackBar(JSON.stringify(response.data));

        }, 400);
      }
    } catch (err) {
      setTimeout(() => {
        snackBar(JSON.stringify(err));

      }, 400);
    }
  };

  useEffect(() => {
    // setNameErrorState(false);
    dispatch(myUploadBookingSelectedList([]));
    dispatch(emptyBookingFileUploadedFile(''));
  }, [patientName]);

  function generateDateRange(startDate) {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setUploadedFile(file);
  //   }
  // };
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array
    if (files.length) {
      setUploadedFile(files); // Set multiple files
    }
  };
console.log('fffffi', uploadedFile);
  const handleCalendarClose = () => {
    setOpen(false);
  };

  const handleCalendarDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    setDateRange(generateDateRange(newDate));
    setOpen(false);
  };

  // const handleNext = () => {
  //   if (!relationValue) {
  //     setIsPatientNameValid(true);
  //     return; // Prevent further actions if validation fails
  //   }
  //   setIsPatientNameValid('');
  //   setIsCheckout(true);
  //   setDialogOpen(true);
  // };

  const handleNext = () => {
    setIsFormEditable(false);
    setIsFormFilled(true);
    // Capture the filled form data
    setFormData({
      relationValue,
      notes,
      uploadedFile,
      amount,
    });

    setIsCheckout(true);

  };

  const handleCheckout = () => {
    // setCheckoutDialogOpen(true);
    // const queryParams = new URLSearchParams({
    //   selectedUnit: selectedUnit,
    //   dropdownOptions: dropdownOptions,
    //   item: JSON.stringify(item),
    //   data: JSON.stringify(data),
    //   doctorSlot: JSON.stringify(doctorSlot),
    //   time: time,
    //   fees: fees,
    //   pname: name,
    //   notes: notes,
    //   unitAddress: unitAddress
    // }).toString();

    // history.push(`/checkout?${queryParams}`);

    onBookingCheckoutEvent()
    // radioButtonRef.current.open();
    onCheckoutApiCall('', false);
  };

  const onCheckoutApiCall = async (hms_id, is_new_user) => {
    setLoader(true);
    const body = {
      // unit_id:slotDetails?.unit_id?.$oid,
      unit_id:
        slotDetails?.slot_type === 'Offline'
          ? slotAddress.unit_id
          : slotDetails?.unit_id,
      // slot_id: slotDetails?._id.$oid,
      doctor_id: doctorDetails?.doctor_id,
      patient_id: patientDetails?.patient_id,
      notes: notes,
      booking_type: slotDetails?.slot_type,
      consult_date: `${slotDetails?.slot_date + 'T' + slotStartTime + ':00'}`,
      consult_time: `${slotDetails?.slot_date + 'T' + slotStartTime + ':00'}`,
      amount: `${amount}`,
      patient_name: patientDetails?.patient_name,
      ref_time: slotStartTime,
      hms_id: hms_id,
      is_new_user: is_new_user,
      files: uploadedFileList ? uploadedFileList : '',
      // `${2023-08-06T05:37:58}`
      // "2028-08-03T08:01:01"
    };
    try {
      console.log(body, 'CHECKOUT BODY');
      const response = await createBooking(body);
      console.log(response, 'CHECKOUT response');
      if (isResponseIsValid(response)) {
        console.log(response, 'response');

        if (response?.data?.kh_user_data) {
          setCheckoutPressed(true);
          console.log('kh_user_data response', response.data.kh_user_data);
          setUhidList(response.data.kh_user_data);
          setTimeout(() => {
            // setLoader(false);
            // radioButtonRef.current.open();
          }, 500);
        } else {
          setTimeout(() => {
            // mixpanel.timeEvent(mixpanel_event.BOOKING_PAYMENT_SESSION)
            //setLoader(false);
            onCallInitialize(response?.data?._id, hms_id);
          }, 500);
        }

        // onCallInitialize(response?.data?._id);
      } else {
        setLoader(false);
        console.log(response?.data?.message, 'BOOKING ERROR');
        if (response?.data?.message) {
          setBookingErrorContent(response?.data?.message);
          setTimeout(() => {
            setBookingErrorPopup(true);
          }, 400);
        } else {
          setTimeout(() => {
            snackBar(Headers.apiError);
          }, 500);
        }
        // setLoader(false);
        // setTimeout(() => {
        //   if (response?.data?.message) {
        //     snackBar(JSON.stringify(response?.data?.message));
        //   } else {
        //     snackBar(Headers.apiError);
        //   }
        // }, 400);
      }
    } catch (err) {
      setLoader(false);
      setTimeout(() => {
        snackBar(JSON.stringify(err));
      }, 400);
    }
  };

  const onCallInitialize = async (id, hms_id) => {
    try {
      const response = await bookingInitialize(id);
      if (isResponseIsValid(response)) {
        console.log('RESP', response.data);
        const mid = Base64.decode(response.data.emid);
        console.log('MID ----->>>>', mid);
        const call_back_url = response.data.call_back_url;
        const order_id = response.data.order_id;
        const tranxToken = response.data.token;
        const am = `${amount}`;

        onPayTmCall(order_id, mid, tranxToken, am, call_back_url, hms_id);

      } else {
        handleError(response?.data?.message || Headers.apiError);
      }
    } catch (err) {
      snackBar(JSON.stringify(err));
    }
  };

  // const onCallInitialize = async (id, hms_id) => {
  //   try {
  //     const response = await bookingInitialize(id);
  //     if (isResponseIsValid(response)) {
  //       console.log('RESP', response.data);
  //       const mid = Base64.decode(response.data.emid);
  //       console.log('MID ----->>>>', mid);
  //       const call_back_url = response.data.call_back_url;
  //       const order_id = response.data.order_id;
  //       const tranxToken = response.data.token;
  //       const am = `${amount}`;
  //       setTimeout(() => {
  //         onPayTmCall(order_id, mid, tranxToken, am, call_back_url, hms_id);
  //       }, 500);
  //     } else {
  //       console.log(response?.data?.message, 'BOOKING INITIALIZE ERROR');
  //       if (response?.data?.message) {
  //         setTimeout(() => {
  //           snackBar(response?.data?.message);
  //         }, 200);
  //       } else {
  //         setTimeout(() => {
  //           snackBar(Headers.apiError);
  //         }, 500);
  //       }
  //     }
  //   } catch (err) {
  //     setTimeout(() => {
  //       snackBar(JSON.stringify(err));
  //     }, 400);
  //   }
  // };

  // const onPayTmCall = (orderId, mid, txnToken, amount, callbackUrl, hms_id) => {
  //   const config = {
  //     root: "",
  //     flow: "DEFAULT",
  //     data: {
  //       orderId: orderId,
  //       token: txnToken,
  //       tokenType: "TXN_TOKEN",
  //       amount: amount
  //     },
  //     handler: {
  //       transactionStatus: function(status) {
  //         console.log("Transaction Status:", status);

  //         const payload = {
  //           paytm_response: status,
  //           hms_id: hms_id,
  //         };

  //         webHooksCall(payload); // Call your webhook with the response
  //         console.log(payload, 'WEBHOOK PAYLOAD');
  //       },
  //       notifyMerchant: function(eventName, data) {
  //         console.log("notifyMerchant", eventName, data);
  //       }
  //     },
  //     merchant: {
  //       redirect: false
  //     },
  //   };

  //   window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
  //     window.Paytm.CheckoutJS.invoke();
  //   }).catch(function onError(error) {
  //     console.log("Error invoking Paytm CheckoutJS", error);
  //   });
  // };

  // const webHooksCall = async (body) => {
  //   setCheckoutLoader(true);
  //   try {
  //     const response = await createWebHooks(body);
  //     if (isResponseIsValid(response)) {
  //       console.log('Reep', response.data);
  //       setCheckoutLoader(false);
  //       setTimeout(() => {
  //         const success_payload = {
  //           isShowPopup: true,
  //           item: response.data,
  //           doctor_id: item?._id.$oid,
  //           type: 'booking',
  //         };
  //         if (response.data.status === 'approved') {
  //           console.log('Payment done');
  //         }
  //         console.log('success_payload', success_payload);
  //         setIsCheckout(true);
  //         history.push('/');
  //       }, 200);
  //     } else {
  //       setCheckoutLoader(false);
  //       const pay_load = {
  //         isShowPopup: true,
  //         item: 'pending',
  //         type: 'booking',
  //       };
  //       setTimeout(() => {
  //         history.push('/');
  //       }, 200);
  //     }
  //   } catch (err) {
  //     setTimeout(() => {
  //       setCheckoutLoader(false);
  //       snackBar(JSON.stringify(err));
  //     }, 400);
  //   }
  // };

  const onPayTmCall = (orderId, mid, txnToken, amount, callbackUrl, hms_id) => {
    const config = {
      root: "",
      flow: "DEFAULT",
      data: {
        orderId: orderId,
        token: txnToken,
        tokenType: "TXN_TOKEN",
        amount: amount
      },
      handler: {
        transactionStatus: function (status) {
          console.log("Transaction Status:", status);

          const payload = {
            paytm_response: status,
            hms_id: hms_id,
          };

          // Call your webhook with the response
          webHooksCall(payload);
          console.log(payload, 'WEBHOOK PAYLOAD');
        },
        notifyMerchant: function (eventName, data) {
          console.log("Notify Merchant:", eventName, data);
        }
      },
      merchant: {
        redirect: true, // Redirect user after payment
        redirectUrl: callbackUrl // Specify the callback URL
      },

    };


    // Initialize and invoke the Paytm payment process
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(() => {
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(error => console.error("Error invoking Paytm CheckoutJS:", error));
    } else {
      console.error("Paytm CheckoutJS is not loaded or available");
    }
  };



  // const webHooksCall = async (body) => {
  //   setCheckoutLoader(true);
  //   try {
  //     const response = await createWebHooks(body);
  //     if (isResponseIsValid(response)) {
  //       handleSuccess(response.data);
  //     } else {
  //       handlePending();
  //     }
  //   } catch (err) {
  //     snackBar(JSON.stringify(err));
  //     setCheckoutLoader(false);
  //   }
  // };

  const webHooksCall = async (payload) => {
    try {
      const response = await createWebHooks(payload);
      if (isResponseIsValid(response)) {
        console.log('Reep', response.data);
        setCheckoutLoader(false);
        setTimeout(() => {


          let success_payload = {
            isShowPopup: true,
            item: response.data,
            doctor_id: doctorDetails?.doctor_id,
            type: 'booking'
          }
          if (response.data.status === 'approved') {
            onMakePaymentEvent('Payment done');
          }
          console.log('success_payload', success_payload)
          setPayCheckoutSuccess('pay_success', JSON.stringify(success_payload));
          // navigation.navigate('HomeScreen', { screen: 'Home' });
          history.push('/');
        }, 200);
      } else {
        setCheckoutLoader(false);
        console.log(JSON.stringify(response), 'WEBHOOKS ERROR');
        // DeviceEventEmitter.emit('webHookData', {
        //   isShowPopup: true,
        //   item: 'pending',
        //   type: 'booking'
        // });
        let pay_load = {
          isShowPopup: true,
          item: 'pending',
          type: 'booking'
        }
        setWebHookCheckout('webhook_popup', JSON.stringify(pay_load))
        setTimeout(() => {
          // navigation.navigate('HomeScreen', { screen: 'Home' });
          //   navigation.navigate('Home');
          history.push('/');
        }, 200);
        // snackBar('Paytm error');
        // console.log('Paytm error')
        // setTimeout(() => {
        //   setCheckoutLoader(false);
        // }, 400);
      }
    } catch (err) {
      setTimeout(() => {
        setCheckoutLoader(false);
        snackBar(JSON.stringify(err));
      }, 400);
    }
  };


  const handleError = (message) => {
    setTimeout(() => {
      snackBar(message);
    }, 200);
  };

  const handleSuccess = (data) => {
    setCheckoutLoader(false);
    const success_payload = {
      isShowPopup: true,
      item: data,
      doctor_id: item?._id.$oid,
      type: 'booking',
    };
    if (data.status === 'approved') {
      console.log('Payment done');
    }
    setIsCheckout(true);
    history.push('/');
  };

  const handlePending = () => {
    setCheckoutLoader(false);
    const pay_load = {
      isShowPopup: true,
      item: 'pending',
      type: 'booking',
    };
    history.push('/');
  };

  const onBookingCheckoutEvent = () => {
    let profile_info = profile_information;
    let property_input = {
      "Mobile number": profile_info.mobile_number,
      "Age": profile_info.age,
      "Gender": profile_info.gender,
      "Relationship": profile_info.relationship,
      "Is Primary User": profile_info.is_primary_user
    }
    // mixpanel.track(mixpanel_event.CHECKOUT, property_input)
  }

  const handlePayatHospital = () => {
    onBookingPayatHospitalEvent();
    onCheckoutPayAtHospitalApiCall('', false);

  };

  const onBookingPayatHospitalEvent = () => {
    let profile_info = profile_information;
    let property_input = {
      "Mobile number": profile_info.mobile_number,
      "Age": profile_info.age,
      "Gender": profile_info.gender,
      "Relationship": profile_info.relationship,
      "Is Primary User": profile_info.is_primary_user
    }
    // mixpanel.track(mixpanel_event.CHECKOUT_PAY_AT_HOSPITAL, property_input)
  }

  const onCheckoutPayAtHospitalApiCall = async (hms_id, is_new_user) => {
    // setLoader(true);
    setCheckoutLoader(true);
    const body = {
      // unit_id:slotDetails?.unit_id?.$oid,
      unit_id:
        slotDetails?.slot_type === 'Offline'
          ? slotAddress.unit_id
          : slotDetails?.unit_id,
      // slot_id: slotDetails?._id.$oid,
      doctor_id: doctorDetails?.doctor_id,
      patient_id: patientDetails?.patient_id,
      notes: patientDetails?.notes,
      booking_type: slotDetails?.slot_type,
      consult_date: `${slotDetails?.slot_date + 'T' + slotStartTime + ':00'}`,
      consult_time: `${slotDetails?.slot_date + 'T' + slotStartTime + ':00'}`,
      amount: `${amount}`,
      patient_name: patientDetails?.patient_name,
      ref_time: slotStartTime,
      hms_id: hms_id,
      is_new_user: is_new_user,
      files: uploadedFileList ? uploadedFileList : ''
    };
    console.log(body, 'BOOKING CREATE BODY PAY AT HOS');
    try {
      console.log(body, 'BOOKING CREATE BODY PAY AT HOS');
      const response = await createBookingPayAtHospital(body);
      console.log(
        JSON.stringify(response),
        'createBookingPayAtHospital RESPONSE',
      );
      if (isResponseIsValid(response)) {
        // setLoader(false);
        if (response?.data?.kh_user_data) {
          setCheckoutPressed(false);
          console.log(response.data.kh_user_data, 'response');
          setUhidList(response.data.kh_user_data);
          setTimeout(() => {
            setCheckoutLoader(false);
            // radioButtonRef.current.open();
          }, 500);
        } else {
          setCheckoutLoader(false);
          setTimeout(() => {
            // DeviceEventEmitter.emit('checkoutData', {
            //   isShowPopup: true,
            //   item: response.data,
            //   type: 'booking'
            // });
            if (response.data.status === 'approved') {
              onMakePaymentEvent('Pay at hospital');
            }
            let success_payload = {
              isShowPopup: true,
              item: response.data,
              type: 'booking'
            }
            console.log('success_payload', success_payload)
            setPayCheckoutSuccess('pay_success', JSON.stringify(success_payload));
            setCheckoutDialogOpen(true);


          }, 200);
        }
      } else {
        // setLoader(false);
        setCheckoutLoader(false);
        // console.log(response?.data?.message, 'BOOKING ERROR');
        if (response?.data?.message) {
          setBookingErrorContent(response?.data?.message);
          setTimeout(() => {
            setBookingErrorPopup(true);
          }, 400);
        } else {
          // DeviceEventEmitter.emit('webHookData', {
          //   isShowPopup: true,
          //   item: 'pending',
          //   type: 'booking'
          // });
          let pay_load = {
            isShowPopup: true,
            item: 'pending',
            type: 'booking'
          }
          setWebHookCheckout('webhook_popup', JSON.stringify(pay_load))
          setTimeout(() => {
            history.push('/');
          }, 500);
        }
        // setLoader(false);
        // setTimeout(() => {
        //   if (response?.data?.message) {
        //     snackBar(JSON.stringify(response?.data?.message));
        //   } else {
        //     snackBar(Headers.apiError);
        //   }
        // }, 400);
      }
    } catch (err) {
      console.log('catch checkout error', err)
      // setLoader(false);
      setCheckoutLoader(false);
      setTimeout(() => {
        snackBar(JSON.stringify(err));
      }, 400);
    }
  };

  const onMakePaymentEvent = (payment_option) => {
    let profile_info = profile_information;
    let property_input = {
      "Mobile number": profile_info.mobile_number,
      "Age": profile_info.age,
      "Gender": profile_info.gender,
      "Relationship": profile_info.relationship,
      "Is Primary User": profile_info.is_primary_user,
      "Consultation type": slotDetails?.slot_type, //Online/Offline
      "Payment amount": amount,
      //  "Doctor name": doctorDetails?.first_name,
      //   "Speciality": doctorDetails?.default_specialization,
      "Hospital Location": slotAddress?.address,
      "Hospital unit": slotAddress?.name,
      "Date selected": formatDateTime(slotDetails?.slot_date, slotStartTime),
      "Time slot": slotStartTime,
      "Patient name": patientDetails?.patient_name,
      "Payment option": payment_option
    }
    console.log('on confirmation booking-+->', property_input)
    // mixpanel.track(mixpanel_event.CONFIRMATION, property_input)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseCheckoutDialog1 = () => {
    // setCheckoutDialogOpen(false);
    setBookingErrorPopup(false);
  };

  const handleCloseCheckoutDialog = () => {
    setCheckoutDialogOpen(false);
    // setBookingErrorPopup(false);
    history.push('/');
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
    dispatch(patient_Notes(event.target.value));
  };

  const handleMenuItemClick = (item) => {
    console.log('notessssss', item);
    setName(item.name);
    dispatch(patient_Name(item.name));
    dispatch(patient_Id(item.app_profile_id));
    // dispatch(patient_Name(item.name));
    setIsPatientNameValid(false);
  };

  return (
    <Box style={{ padding: 0, margin: 0 }}>
      <Header
        dropdownOptions={dropdownOptions}
        selectedUnit={selectedUnit}
      />
      {/* <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 2,
        padding: 1,
        flexDirection: { xs: 'column', sm: 'row' }
      }}>
        <Avatar
          src="/path/to/doctor-image.jpg"
          alt="Doctor Image"
          sx={{
            width: 80,
            height: 80,
            marginBottom: { xs: 0, sm: 2 },
            marginRight: { xs: 0, sm: 2 },
            padding: 2
          }}
        />
        <Box sx={{
          textAlign: 'left',
          justifyContent: 'space-between',
          padding: 1,
          marginLeft: 2
        }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>{item.doctorName}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>{item.designation}</Typography>
        </Box>
        <Box sx={{
          textAlign: 'left',
          justifyContent: 'space-between',
          padding: 1,
          marginLeft: 2
        }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>{item.specialtyName}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>
            <AccessTimeIcon /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}
          </Typography>
        </Box>
      </Box> */}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: '0 0.5vw 1vw rgba(0.2,0.2,0.2,0.2)', }}>
        <Avatar
          src={item.photo}
          alt="Doctor Image"
          sx={{ width: 50, height: 55, marginRight: 2, ml: 4, mt: 1, }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '16px' }}>{item.first_name}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(item.designation) ? item.designation.join(', ') : item.designation}</Typography>
        </Box>
        <Box sx={{ ml: 8 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{item.default_specialization}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography>
        </Box>
        <Box sx={{ ml: 12 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{item.languages ?
            (Array.isArray(item.languages) ? item.languages.join(', ') : item.languages) :
            'Tamil, English'
          }</Typography>
          {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
        </Box>
      </Box>

      {!isFormFilled ? (
        <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, backgroundColor: '#FFFFFF', borderRadius: 1, width: { xs: '100%', md: '60%' }, mx: 'auto', my: 2 }}>
          <Button
            onClick={() => window.history.back()}
            sx={{
              marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}
          >
            &lt; Select Patient
          </Button>

          <FormControl variant="outlined">
            <StyledSelect
              value={relationValue}
              onChange={handleRelationChange}
              label="Select Patient"
              sx={{ fontFamily: 'Poppins', width: '50%', mb: 2 }}
            >
              {relationData.map((relation, index) => (
                <MenuItem
                  key={index}
                  value={relation.name}
                  onClick={() => handleMenuItemClick(relation)}
                  sx={{ color: '#000', fontFamily: 'Poppins' }}
                >
                  {relation.name}
                </MenuItem>
              ))}
            </StyledSelect>
            {/* {isPatientNameValid && (
            <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red' }}>
              Please select a patient name.
            </FormHelperText>
          )} */}
          </FormControl>

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2 }}>
            Note: Prescription will be provided in the name of the patient selected. If you don’t find the patient in the list,
            <a href="/add-patient" style={{ color: '#962067' }}> Add patient</a>
          </Typography>

          <StyledTextField
            fullWidth
            label="Notes to doctor"
            multiline
            rows={4}
            variant="outlined"
            sx={{ mb: 0, width: '70%', fontFamily: 'Poppins' }}
            value={notes}
            onChange={handleNotesChange}
          />

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2 }}>
            Maximum 500 characters.
          </Typography>

          <Paper sx={{
            border: '1px dashed #707070',
            padding: 2,
            textAlign: 'center',
            borderRadius: 1,
            mb: 2,
            width: '70%',
            borderRadius: '10px',
          }} elevation={0}>
            <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, textTransform: 'none', fontSize: '14px' }} gutterBottom>
              Upload files
            </Typography>
            <Button component="label" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, textTransform: 'none', fontSize: '14px' }}>
              Choose your file to upload here
              <input type="file" hidden onChange={handleFileUpload} />
            </Button>
          </Paper>
          <Paper>
            {uploadedFile && uploadedFile.length > 0 && (
              <Box sx={{ width: 'auto', mt: 1, mb: 1}}>
                {uploadedFile.map((file, index) => (
                  <Typography key={index} variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px' }}>
                    <LiaFileAlt sx={{color: COLORS.primaryColor}}/> {file.name} ({(file.size / 1024).toFixed(2)} KB)
                  </Typography>
                ))}
              </Box>
            )}
          </Paper>
          <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '10px', fontFamily: 'Poppins' }}>
            Support format (Max 1 MB): PNG, JPG, JPEG, HEIC, PDF
          </Typography>


          {!isCheckout ? (
            <Box display="flex" justifyContent='center'>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#962067',
                  color: '#fff',
                  p: 1,
                  borderRadius: 8,
                  textTransform: 'none',
                  justifyContent: 'center',
                  fontFamily: 'Poppins',
                  mt: 2,
                  width: { xs: '30%', md: '30%' },
                  '&:hover': {
                    backgroundColor: '#962067',
                    borderColor: '#ffe6f2',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                  },
                }}
                onClick={handleNext}
              >
                Continue
              </Button>
            </Box>
          ) : (
            <Box sx={{
              //   display: 'flex',
              //   // flexDirection: 'column',
              //   // justifyContent: 'space-between',
              //   padding: 2,
              //   alignItems: 'center',
              //   mt: 0
            }}>
              <Box display="flex" justifyContent="center" >
                <Box display="flex" justifyContent='space-between' flexDirection='row' backgroundColor='#DCDCDC33' mt={2} border='0px solid #00000029' borderRadius={1} width='50%' boxShadow='0px 2px 4px rgba(0, 0.1, 0.1, 0.1) #00000029'>
                  <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>To pay:</Typography>
                  <Typography sx={{ fontFamily: 'Poppins', mr: 4 }}>{amount}</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection='row' justifyContent="center" alignItems='center'>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#962067',
                    color: '#fff',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    '&:hover': {
                      backgroundColor: '#962067',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                    },
                    // ':hover': {
                    //   backgroundColor: COLORS.primaryColor,
                    // },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                    },
                  }}
                  onClick={handleCheckout}
                >
                  Pay Now
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#962067',
                    color: '#962067',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    ml: 1,
                    '&:hover': {
                      backgroundColor: '#ffffff',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                    },
                    // ':hover': {
                    //   backgroundColor: COLORS.primaryColor,
                    // },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                    },
                  }}
                  onClick={handlePayatHospital}
                >
                  Pay at hospital
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      ) : (
        <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, backgroundColor: '#FFFFFF', borderRadius: 1, width: { xs: '100%', md: '60%' }, mx: 'auto', my: 2 }}>
          <Button
            onClick={() => window.history.back()}
            sx={{
              marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}
          >
            &lt; Patient Details
          </Button>

          <FormControl variant="outlined" disabled={!isFormEditable}>
            <StyledSelect
              value={formData.relationValue}
              onChange={handleRelationChange}
              label="Select Patient"
              sx={{ fontFamily: 'Poppins', width: '50%', mb: 2 }}
              disabled={!isFormEditable}
            >
              {relationData.map((relation, index) => (
                <MenuItem
                  key={index}
                  value={relation.name}
                  onClick={() => handleMenuItemClick(relation)}
                  sx={{ color: '#000', fontFamily: 'Poppins' }}
                  disabled={!isFormEditable}
                >
                  {relation.name}
                </MenuItem>
              ))}
            </StyledSelect>
            {/* {isPatientNameValid && (
            <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red' }}>
              Please select a patient name.
            </FormHelperText>
          )} */}
          </FormControl>

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2 }}>
            Note: Prescription will be provided in the name of the patient selected. If you don’t find the patient in the list,
            <a href="/add-patient" style={{ color: '#962067' }}> Add patient</a>
          </Typography>

          <StyledTextField
            fullWidth
            label="Notes to doctor"
            multiline
            rows={4}
            variant="outlined"
            sx={{ mb: 0, width: '70%', fontFamily: 'Poppins' }}
            value={formData.notes}
            onChange={handleNotesChange}
            disabled={!isFormEditable}
          />

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2 }}>
            Maximum 500 characters.
          </Typography>

          <Paper sx={{
            border: '1px dashed #707070',
            padding: 2,
            textAlign: 'center',
            borderRadius: 1,
            mb: 2,
            width: '70%',
            borderRadius: '10px',
          }} elevation={0}>
            <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, textTransform: 'none', fontSize: '14px' }} gutterBottom>
              Upload files
            </Typography>
            <Button component="label" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, textTransform: 'none', fontSize: '14px' }}>
              Choose your file to upload here
              <input type="file" hidden onChange={handleFileUpload} />
            </Button>
          </Paper>
          {uploadedFile && (
            <Box sx={{ marginTop: 2, width: '70%' }}>
              <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px' }}>
                Uploaded File: {uploadedFile.name} ({(uploadedFile.size / 1024).toFixed(2)} KB)
              </Typography>
            </Box>
          )}
          <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '10px', fontFamily: 'Poppins' }}>
            Support format (Max 1 MB): PNG, JPG, JPEG, HEIC, PDF
          </Typography>


          {!isCheckout ? (
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#962067',
                  color: '#fff',
                  p: 1,
                  borderRadius: 8,
                  textTransform: 'none',
                  justifyContent: 'center',
                  mt: 2,
                  width: { xs: '30%', md: '30%' },
                  '&:hover': {
                    backgroundColor: '#962067',
                    borderColor: '#ffe6f2',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                  },
                  ':active': {
                    boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                  },
                }}
                onClick={handleNext}
              >
                Continue
              </Button>
            </Box>
          ) : page == 1 ? (  // This condition checks if page is 1
            <Box>
              <Box display="flex" >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  backgroundColor="#DCDCDC33"
                  mt={2}
                  border="0px solid #00000029"
                  borderRadius={1}
                  width="70%"
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                >
                  <Typography sx={{ fontFamily: 'Poppins', ml: 2, mt:1, mb: 1 }}>To pay:</Typography>
                  <Typography sx={{ fontFamily: 'Poppins', mr: 4,mt:1, mb: 1 }}>{amount}</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#962067',
                    color: '#fff',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    '&:hover': {
                      backgroundColor: '#962067',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`,
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`,
                    },
                  }}
                  onClick={handleCheckout}
                >
                  Pay Now
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#962067',
                    color: '#962067',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    ml: 1,
                    '&:hover': {
                      backgroundColor: '#ffffff',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`,
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`,
                    },
                  }}
                  onClick={handlePayatHospital}
                >
                  Pay at hospital
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box display="flex" >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  backgroundColor="#DCDCDC33"
                  mt={2}
                  border="0px solid #00000029"
                  borderRadius={1}
                  width="50%"
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                >
                  <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>To pay:</Typography>
                  <Typography sx={{ fontFamily: 'Poppins', mr: 4 }}>{amount}</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#962067',
                    color: '#fff',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    '&:hover': {
                      backgroundColor: '#962067',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`,
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`,
                    },
                  }}
                  onClick={handleCheckout}
                >
                  Pay Now
                </Button>
              </Box>
            </Box>
          )}

        </Paper>
      )}

      <Box sx={{
        display: 'flex',
        width: '100%',
        height: '80px',
        backgroundColor: '#962067',
        borderRadius: '10px 10px 0 0',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 1,
      }}>
        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: '#fff' }}>
          © 2024 Patient Appointment Booking. All rights reserved.
        </Typography>
      </Box>

      <Dialog open={open1} onClose={handleCalendarClose}>
        <DialogTitle>Select Date</DialogTitle>
        <DialogContent>
          <TextField
            type="date"
            fullWidth
            onChange={handleCalendarDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCalendarClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Continue</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>
            Please review the patient details before proceeding.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleCloseDialog();
              // Perform any necessary actions on continue
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isCheckoutDialogOpen} onClose={handleCloseCheckoutDialog}>

        <IconButton sx={{ fontSize: 48 }}>
          {/* <CancelIcon sx={{ fontSize: 'inherit', color: 'failure.main', mt: '20px' }} /> */}
          <CheckCircleIcon sx={{ fontSize: 'inherit', color: 'success.main', mt: '20px' }} />
        </IconButton>
        <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Poppins', mb: 1, color: COLORS.textColor }}>
          Booked!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
            Your in-person consultation with {item.doctorName} on {date}, {time} is confirmed!
          </Typography>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleCloseCheckoutDialog} variant="contained" sx={{
              backgroundColor: '#962067',
              color: '#fff',
              p: 1,
              borderRadius: 8,
              textTransform: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              width: '30%',
              '&:hover': {
                backgroundColor: '#962067',
                borderColor: '#ffe6f2',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}>Okay</Button>
          </DialogActions>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px', mb: '20px' }}>
            <span style={{ fontFamily: 'Poppins', color: COLORS.textColor }}>Note: </span>The appointment time is subject to last-minute change due to any unavoidable emergencies to be attended by the doctors.
          </Typography>
        </DialogContent>

      </Dialog>

      <Dialog open={bookingErrorPopup} onClose={handleCloseCheckoutDialog1}>

        <IconButton sx={{ fontSize: 48 }}>
          <CancelIcon sx={{ fontSize: 'inherit', color: 'failure.main', mt: '20px' }} />
        </IconButton>
        <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Poppins', mb: 1, color: COLORS.textColor }}>
          Error!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
            {bookingErrorContent}
          </Typography>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleCloseCheckoutDialog1} variant="contained" sx={{
              backgroundColor: '#962067',
              color: '#fff',
              p: 1,
              borderRadius: 8,
              textTransform: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              width: '30%',
              '&:hover': {
                backgroundColor: '#962067',
                borderColor: '#ffe6f2',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}>Okay</Button>
          </DialogActions>
          {/* <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px', mb: '20px' }}>
            <span style={{ fontFamily: 'Poppins', color: COLORS.textColor }}>Note: </span>The appointment time is subject to last-minute change due to any unavoidable emergencies to be attended by the doctors.
          </Typography> */}
        </DialogContent>

      </Dialog>
    </Box>
  );

};

export default SelectPatient;
