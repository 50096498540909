import Headers from '../constants/Headers';
import httpClient from './httpClient';
import profileSwitchHttpClient from './profileSwitchHttpClient';

export const onApiCall = async ({method, url, data, isFileUpload = false, profileSwitch = false}) => {
  console.log('profileSwitch',url,profileSwitch)
  const constructHeaders = () => {
    if (isFileUpload) {
      return {
        common: {'Content-Type': 'multipart/form-data'},
      };
    } else {
      return {
        common: {'Content-Type': 'application/json'},
      };
    }
  };
 // console.log(`===> Request: ${method} ${url}`);
  try {
    if(profileSwitch !== true){
      const response = await httpClient.request({
        url,
        method,
        data,
        headers: constructHeaders(),
      });
      // console.log('middile ware console-->',response)

         // console.log(`===> Response 1111111111: ${JSON.stringify(response)}`)
      return {
        data: response?.data,
        status: response?.status,
      };
    }else{
      const response = await profileSwitchHttpClient.request({
        url,
        method,
        data,
        headers: constructHeaders(),
      });
      console.log('middile ware console-->',response)
      return {
        data: response?.data,
        status: response?.status,
      };
    }
    
  } catch (error) {
    console.log(`Kauvery API error: ${JSON.stringify(error.response)}`)

   //   console.log(`Kauvery API error: ${JSON.stringify(error.response)}`)
    if (error.response) {
      return {
        data: error.response.data.error,
        status: error.response.status,
      };
    } else if (error.request) {
      return {
        data: Headers.apiError,
      };
    } else {
      return {
        data: Headers.apiError,
      };
    }
  }
};
