import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  IconButton,
  Button,
  Avatar,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material';
import {
  bookingInitialize,
  createBooking,
  createBookingPayAtHospital,
  createWebHooks,
} from '../utils/apiCalls';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Header from './HomeHeader';
import { COLORS } from '../constants/Theme';
import { useHistory, useLocation } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { familyMembersList, requestNow } from '../utils/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { patientDetails, patient_Id, patient_Name, patient_Notes } from '../store/actions/bookingActions';
import { UploadDuplicateBookingList, UploadFileBookingList, emptyBookingFileUploadedFile, removeBookingFileUploadedFile } from '../store/actions/uploadBookingActions';
import { KauvaryFileUpload } from '../utils/KauveryFileUpload';
import { EventEmitter } from 'events';
import { getSecondaryProfileID, getUserInformation, setPayCheckoutSuccess, setWebHookCheckout } from '../utils/LocalStorage';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { Base64 } from 'js-base64';
import { currentProfileRawData, familyReloadData } from '../store/actions/homeActions';
import { emptyBookingUploadRecord, emptyUploadRecord, myUploadSelectedClear, myUploadBookingSelectedList, onMyUploadBookingSelection } from '../store/actions/recordActions';
import axios from 'axios';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { formatDateTime } from '../utils/helpers';

const StyledTextField = styled(TextField)({
  // marginTop: '20px',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
  '& .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for label
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for placeholder
  },
});

const StyledSelect = styled((props) => <TextField select {...props} />)({
  marginTop: '20px',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
  '& .MuiInputBase-root': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for label
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiSelect-icon': {
    color: COLORS.primaryColor, // Custom color for dropdown icon
  },
  '& .Mui-focused .MuiInputLabel-root': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .Mui-focused .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .Mui-focused .MuiInputBase-root': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for placeholder
  },
});

const eventEmitter = new EventEmitter();

const SelectPatient = () => {
  // const classes = useStyles;
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedSlot, setSelectedSlot] = useState(null);
  const [open, setOpen] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCheckoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState(generateDateRange(new Date()));
  // const [dropdownOptions, setDropdownOptions] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [relationData, setRelationData] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);
  const [userToken, setUserToken] = useState('');
  const [nameErrorState, SetNameErrorState] = useState(false);
  const [notesErrorState, SetNotesErrorState] = useState(false);
  const [name, setName] = useState('');
  const [nameErrorText, SetNameErrorText] = useState('');
  const [signUpState, setSignUpState] = useState(false);
  const [relationValue, setRelationValue] = useState('');
  const [notes, setNotes] = useState('');
  const [slotid, setSlotid] = useState(1687304);
  const [bookingErrorContent, setBookingErrorContent] = useState('');
  const [uhidList, setUhidList] = useState([]);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [isPatientNameValid, setIsPatientNameValid] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [message, setMessage] = useState('');
  const [requestid, setRequestid] = useState('');

  const [isFormFilled, setIsFormFilled] = useState(false);
  const [formData, setFormData] = useState({
    relationValue: '',
    notes: '',
  });

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [patientId, setPaitentId] = useState('');
  const unitName = useSelector(
    state => state?.authReducer?.unitName,
  );
  const patientDetails = useSelector(
    // Patient details
    state => state?.bookingReducer?.patientDetails,
  );

  const profile_information = useSelector(
    state => state?.homeReducer?.profile_info,
  );

  const currentUser = useSelector(
    state => state?.homeReducer?.currentProfileName,
  );
  const currentProfileData = useSelector(
    state => state?.homeReducer?.currentProfileRawData,
  );

  const queryParams = new URLSearchParams(location.search);
  const selectedUnit = queryParams.get('selectedUnit');
  const item = JSON.parse(queryParams.get('item'));
//   const doctorSlot = JSON.parse(queryParams.get('doctorSlot'));
//   const dropdownOptions = JSON.parse(queryParams.get('dropdownOptions'));
  const unitAddress = queryParams.get('unitAddress');
//   const date = queryParams.get('date');
  const page = queryParams.get('page');
  const [bookingErrorPopup, setBookingErrorPopup] = useState(false);
  // const [bookingErrorContent, setBookingErrorContent] = useState('');

  const [checkoutPressed, setCheckoutPressed] = useState(false);
  console.log('cccccccc', item);

  useEffect(() => {
    if (relationData.length > 0) {
      setRelationValue(relationData[0].name);
      dispatch(patient_Name(relationData[0].name));
      dispatch(patient_Id(relationData[0].app_profile_id));
    }
  }, [relationData]);

  useEffect(() => {
    const familyMembersSelectPatientListener = eventEmitter.addListener('familyMembersSelectPatient', (event) => {
      familyMembersApiCall();
      console.log(event, "Event");
      // if (event.isShowPopup) {
      //   setProfileCreatedPopup(event.isShowPopup);
      // }
      if (event.isName) {
        setName(event.isName);
      }
      getUserInformation('User_Data').then(res => {
        let response = JSON.parse(res);
        setUserToken(response.token);
      });
      familyMembersApiCall();
    });

    const sectionListReloadListener = eventEmitter.addListener('SectionListReload', (event) => {
      setTimeout(() => {
        setReloadKey(reloadKey + 1);
      }, 1000);
    });

    getUserInformation('User_Data').then(res => {
      let response = JSON.parse(res);
      setUserToken(response.token);
    });

    familyMembersApiCall();

    return () => {
      // familyMembersSelectPatientListener.remove();
      // sectionListReloadListener.remove();
    };
  }, [reloadKey]);

  const handleRelationChange = (event) => {
    setRelationValue(event.target.value);
    setIsPatientNameValid(true);
  };

  const familyMembersApiCall = async () => {

    try {
      const response = await familyMembersList();
      if (isResponseIsValid(response)) {
        console.log(JSON.stringify(response.data.data));
        setRelationData(response.data.data);
        dispatch(currentProfileRawData(response.data.data));
        setTimeout(() => {

        }, 400);
      } else {
        setTimeout(() => {
          snackBar(JSON.stringify(response.data));

        }, 400);
      }
    } catch (err) {
      setTimeout(() => {
        snackBar(JSON.stringify(err));

      }, 400);
    }
  };

  function generateDateRange(startDate) {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleCalendarClose = () => {
    setOpen(false);
  };

  const handleCalendarDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    setDateRange(generateDateRange(newDate));
    setOpen(false);
  };

  // const handleNext = () => {
  //   if (!relationValue) {
  //     setIsPatientNameValid(true);
  //     return; // Prevent further actions if validation fails
  //   }
  //   setIsPatientNameValid('');
  //   setIsCheckout(true);
  //   setDialogOpen(true);
  // };

  const handleNext = () => {
    // setIsFormEditable(false);
    // setIsFormFilled(true);
    // Capture the filled form data
    setFormData({
      relationValue,
      notes,
    });

    onRequestNowApiCall();
  };

  const onRequestNowApiCall = async () => {
    setLoader(true);
    const body = {
      doctor_id: item?._id.$oid,
      patient_id: patientDetails?.patient_id,
      notes: notes,
      unit_name: unitName,
    };
    try {
      console.log(body, 'REQUEST NOW BODY');
      const response = await requestNow(body);
      console.log(response, 'Request Response');
      if (isResponseIsValid(response)) {
        setTimeout(() => {
          setLoader(false);
          eventEmitter.emit('requestNowData', {
            isShowPopup: true,
            item: response.data,
          });
          setMessage(JSON.stringify(response?.data?.message));
            setRequestid(JSON.stringify(response?.data?.request_id));
            setCheckoutDialogOpen(true);
        //   history.push('/');
        }, 200);
      } else {
        setLoader(false);
        setTimeout(() => {
          if (response?.data?.message) {
            snackBar(JSON.stringify(response?.data?.message));
            setMessage(JSON.stringify(response?.data?.message));
            // setRequestid(JSON.stringify(response?.data?.request_id));
            setBookingErrorPopup(true);
          } else {
            snackBar(Headers.apiError);
          }
        }, 400);
      }
    } catch (err) {
      setLoader(false);
      setTimeout(() => {
        snackBar(JSON.stringify(err));
      }, 400);
    }
  };

  const handleCheckout = () => {
    

    // history.push(`/checkout?${queryParams}`);

    onBookingCheckoutEvent();

  };

  const onBookingCheckoutEvent = () => {
    let profile_info = profile_information;
    let property_input = {
      "Mobile number": profile_info.mobile_number,
      "Age": profile_info.age,
      "Gender": profile_info.gender,
      "Relationship": profile_info.relationship,
      "Is Primary User": profile_info.is_primary_user
    }
    // mixpanel.track(mixpanel_event.CHECKOUT, property_input)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseCheckoutDialog = () => {
    setCheckoutDialogOpen(false);
    history.push('/');
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
    dispatch(patient_Notes(event.target.value));
  };

  const handleMenuItemClick = (item) => {
    console.log('notessssss', item);
    setName(item.name);
    dispatch(patient_Name(item.name));
    dispatch(patient_Id(item.app_profile_id));
    // dispatch(patient_Name(item.name));
    setIsPatientNameValid(false);
  };

  return (
    <Box style={{ padding: 0, margin: 0 }}>
      <Header
        // dropdownOptions={dropdownOptions}
        selectedUnit={selectedUnit}
      />
      {/* <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 2,
        padding: 1,
        flexDirection: { xs: 'column', sm: 'row' }
      }}>
        <Avatar
          src="/path/to/doctor-image.jpg"
          alt="Doctor Image"
          sx={{
            width: 80,
            height: 80,
            marginBottom: { xs: 0, sm: 2 },
            marginRight: { xs: 0, sm: 2 },
            padding: 2
          }}
        />
        <Box sx={{
          textAlign: 'left',
          justifyContent: 'space-between',
          padding: 1,
          marginLeft: 2
        }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>{item.doctorName}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>{item.designation}</Typography>
        </Box>
        <Box sx={{
          textAlign: 'left',
          justifyContent: 'space-between',
          padding: 1,
          marginLeft: 2
        }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>{item.specialtyName}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>
            <AccessTimeIcon /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}
          </Typography>
        </Box>
      </Box> */}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: 1}}>
        <Avatar
          src={item.photo}
          alt="Doctor Image"
          sx={{ width: 50, height: 55, marginRight: 2, ml: 4, mt: 1, }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '16px' }}>{item.first_name}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(item.designation) ? item.designation.join(', ') : item.designation}</Typography>
        </Box>
        <Box sx={{ ml: 8 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{item.default_specialization}</Typography>
          {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
        </Box>
        <Box sx={{ ml: 12 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{item.languages ?
            (Array.isArray(item.languages) ? item.languages.join(', ') : item.languages) :
            'Tamil, English'
          }</Typography>
          {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
        </Box>
      </Box>

      {!isFormFilled ? (
        <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, backgroundColor: '#FFFFFF', borderRadius: 1, width: { xs: '100%', md: '60%' }, mx: 'auto', my: 2 }}>
          <Button
            onClick={() => window.history.back()}
            sx={{
              marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}
          >
            &lt; Select Patient
          </Button>

          <FormControl variant="outlined">
            <StyledSelect
              value={relationValue}
              onChange={handleRelationChange}
              label="Select Patient"
              sx={{ fontFamily: 'Poppins', width: '50%', mb: 2 }}
            >
              {relationData.map((relation, index) => (
                <MenuItem
                  key={index}
                  value={relation.name}
                  onClick={() => handleMenuItemClick(relation)}
                  sx={{ color: '#000', fontFamily: 'Poppins' }}
                >
                  {relation.name}
                </MenuItem>
              ))}
            </StyledSelect>
            {/* {isPatientNameValid && (
            <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red' }}>
              Please select a patient name.
            </FormHelperText>
          )} */}
          </FormControl>

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2 }}>
            Note: Prescription will be provided in the name of the patient selected. If you don’t find the patient in the list,
            <a href="/add-patient" style={{ color: '#962067' }}> Add patient</a>
          </Typography>

          <StyledTextField
            fullWidth
            label="Notes to doctor"
            multiline
            rows={4}
            variant="outlined"
            sx={{ mb: 0, width: '70%', fontFamily: 'Poppins' }}
            value={notes}
            onChange={handleNotesChange}
          />

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2, mt: 1 }}>
            Maximum 500 characters.
          </Typography>

          {!isCheckout ? (
            <Box display="flex" justifyContent='center'>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#ffd700',
                  color: '#fff',
                  p: 1,
                  borderRadius: 8,
                  textTransform: 'none',
                  justifyContent: 'center',
                  fontFamily: 'Poppins',
                  mt: 2,
                  width: { xs: '30%', md: '30%' },
                  '&:hover': {
                    backgroundColor: '#ffd700',
                    borderColor: '#ffe6f2',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                  },
                }}
                onClick={handleNext}
              >
                Confirm Request
              </Button>
            </Box>
          ) : (
            <Box sx={{
              //   display: 'flex',
              //   // flexDirection: 'column',
              //   // justifyContent: 'space-between',
              //   padding: 2,
              //   alignItems: 'center',
              //   mt: 0
            }}>
              <Box display="flex" justifyContent="center" >
                <Box display="flex" justifyContent='space-between' flexDirection='row' backgroundColor='#DCDCDC33' mt={2} border='0px solid #00000029' borderRadius={1} width='50%' boxShadow='0px 2px 4px rgba(0, 0.1, 0.1, 0.1) #00000029'>
                  <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>To pay:</Typography>
                  {/* <Typography sx={{ fontFamily: 'Poppins', mr: 4 }}>{amount}</Typography> */}
                </Box>
              </Box>
              <Box display="flex" flexDirection='row' justifyContent="center" alignItems='center'>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#962067',
                    color: '#fff',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    '&:hover': {
                      backgroundColor: '#962067',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                    },
                    // ':hover': {
                    //   backgroundColor: COLORS.primaryColor,
                    // },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                    },
                  }}
                  onClick={handleCheckout}
                >
                  Pay Now
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#962067',
                    color: '#962067',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    ml: 1,
                    '&:hover': {
                      backgroundColor: '#ffffff',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                    },
                    // ':hover': {
                    //   backgroundColor: COLORS.primaryColor,
                    // },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                    },
                  }}
                //   onClick={handlePayatHospital}
                >
                  Pay at hospital
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      ) : (
        <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, backgroundColor: '#FFFFFF', borderRadius: 1, width: { xs: '100%', md: '60%' }, mx: 'auto', my: 2 }}>
          <Button
            onClick={() => window.history.back()}
            sx={{
              marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}
          >
            &lt; Patient Details
          </Button>

          <FormControl variant="outlined" disabled={!isFormEditable}>
            <StyledSelect
              value={formData.relationValue}
              onChange={handleRelationChange}
              label="Select Patient"
              sx={{ fontFamily: 'Poppins', width: '50%', mb: 2 }}
              disabled={!isFormEditable}
            >
              {relationData.map((relation, index) => (
                <MenuItem
                  key={index}
                  value={relation.name}
                  onClick={() => handleMenuItemClick(relation)}
                  sx={{ color: '#000', fontFamily: 'Poppins' }}
                  disabled={!isFormEditable}
                >
                  {relation.name}
                </MenuItem>
              ))}
            </StyledSelect>
            {/* {isPatientNameValid && (
            <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red' }}>
              Please select a patient name.
            </FormHelperText>
          )} */}
          </FormControl>

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2 }}>
            Note: Prescription will be provided in the name of the patient selected. If you don’t find the patient in the list,
            <a href="/add-patient" style={{ color: '#962067' }}> Add patient</a>
          </Typography>

          <StyledTextField
            fullWidth
            label="Notes to doctor"
            multiline
            rows={4}
            variant="outlined"
            sx={{ mb: 0, width: '70%', fontFamily: 'Poppins' }}
            value={formData.notes}
            onChange={handleNotesChange}
            disabled={!isFormEditable}
          />

          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', ml: 1, mb: 2 }}>
            Maximum 500 characters.
          </Typography>


          {!isCheckout ? (
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#ffd700',
                  color: '#fff',
                  p: 1,
                  borderRadius: 8,
                  textTransform: 'none',
                  justifyContent: 'center',
                  mt: 2,
                  width: { xs: '30%', md: '30%' },
                  '&:hover': {
                    backgroundColor: '#ffd700',
                    borderColor: '#ffe6f2',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                  },
                  ':active': {
                    boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                  },
                }}
                onClick={handleNext}
              >
                Request Confirm
              </Button>
            </Box>
          ) : page == 1 ? (  // This condition checks if page is 1
            <Box>
              <Box display="flex" justifyContent="center">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  backgroundColor="#DCDCDC33"
                  mt={2}
                  border="0px solid #00000029"
                  borderRadius={1}
                  width="50%"
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                >
                  <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>To pay:</Typography>
                  {/* <Typography sx={{ fontFamily: 'Poppins', mr: 4 }}>{amount}</Typography> */}
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#962067',
                    color: '#fff',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    '&:hover': {
                      backgroundColor: '#962067',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`,
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`,
                    },
                  }}
                  onClick={handleCheckout}
                >
                  Pay Now
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#962067',
                    color: '#962067',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    ml: 1,
                    '&:hover': {
                      backgroundColor: '#ffffff',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`,
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`,
                    },
                  }}
                //   onClick={handlePayatHospital}
                >
                  Pay at hospital
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box display="flex" justifyContent="center">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  backgroundColor="#DCDCDC33"
                  mt={2}
                  border="0px solid #00000029"
                  borderRadius={1}
                  width="50%"
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                >
                  <Typography sx={{ fontFamily: 'Poppins', ml: 2 }}>To pay:</Typography>
                  {/* <Typography sx={{ fontFamily: 'Poppins', mr: 4 }}>{amount}</Typography> */}
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#962067',
                    color: '#fff',
                    p: 1,
                    borderRadius: 8,
                    textTransform: 'none',
                    justifyContent: 'center',
                    mt: 2,
                    width: '30%',
                    '&:hover': {
                      backgroundColor: '#962067',
                      borderColor: '#ffe6f2',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`,
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px transparent`,
                    },
                  }}
                  onClick={handleCheckout}
                >
                  Pay Now
                </Button>
              </Box>
            </Box>
          )}

        </Paper>
      )}

      <Box sx={{
        display: 'flex',
        width: '100%',
        height: '80px',
        backgroundColor: '#962067',
        borderRadius: '10px 10px 0 0',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 1,
      }}>
        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: '#fff' }}>
          © 2024 Patient Appointment Booking. All rights reserved.
        </Typography>
      </Box>

      <Dialog open={open} onClose={handleCalendarClose}>
        <DialogTitle>Select Date</DialogTitle>
        <DialogContent>
          <TextField
            type="date"
            fullWidth
            onChange={handleCalendarDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCalendarClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Continue</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>
            Please review the patient details before proceeding.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleCloseDialog();
              // Perform any necessary actions on continue
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isCheckoutDialogOpen} onClose={handleCloseCheckoutDialog}>

        {/* <IconButton sx={{ fontSize: 48 }}>
          <CheckCircleIcon sx={{ fontSize: 'inherit', color: 'success.main', mt: '20px' }} />
        </IconButton> */}
        <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Poppins', mb: 1, color: COLORS.textColor }}>
          Ticket Id: {requestid}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
          A ticket is generated to book your appointment. We will notify you shortly after confirmation.
          </Typography>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleCloseCheckoutDialog} variant="contained" sx={{
              backgroundColor: '#962067',
              color: '#fff',
              p: 1,
              borderRadius: 8,
              textTransform: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              width: '30%',
              '&:hover': {
                backgroundColor: '#962067',
                borderColor: '#ffe6f2',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}>Okay</Button>
          </DialogActions>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px', mb: '20px' }}>
            <span style={{ fontFamily: 'Poppins', color: COLORS.textColor }}>Note: </span>The appointment time is subject to last-minute change due to any unavoidable emergencies to be attended by the doctors.
          </Typography>
        </DialogContent>

      </Dialog>
    </Box>
  );

};

export default SelectPatient;
