import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Images from "../constants/Images";
import { COLORS } from "../constants/Theme";

const Footer = () => {
  const footerLinks = {
    About: [
      { label: "Overview", href: "/overview" },
      { label: "Our Management Team", href: "/management-team" },
      { label: "Awards", href: "/awards" },
      { label: "Careers", href: "/careers" },
    ],
    "Centers of Excellence": [
      { label: "Cardiac Science", href: "/cardiac-science" },
      { label: "Neurology", href: "/neurology" },
      { label: "General Medicine", href: "/general-medicine" },
      { label: "Diabetology", href: "/diabetology" },
    ],
    Specialties: [
      { label: "Anesthesiology", href: "/anesthesiology" },
      { label: "Critical Care", href: "/critical-care" },
      { label: "Dental Maxillofacial", href: "/dental-maxillofacial" },
      { label: "Dermatology", href: "/dermatology" },
    ],
    "Patients & Visitors": [
      { label: "Overview", href: "/patients-overview" },
      { label: "Appointments", href: "/appointments" },
      { label: "Admissions", href: "/admissions" },
      { label: "Preparing for Surgery", href: "/preparing-for-surgery" },
    ],
  };

  return (
    <Box
      sx={{
        backgroundColor: "#8B2E5D",
        color: "white",
        padding: "40px",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        position: "relative",
        mt: 4,
      }}
    >
      {/* Logo Section */}
      <Box>
        <img
          src={Images.logo}
          alt="Kauvery Hospital Logo"
          style={{ width: "180px", marginBottom: "16px", marginLeft: '40px' }}
        />        
      </Box>

      {/* Footer Links */}
      {Object.entries(footerLinks).map(([section, links]) => (
        <Box key={section} sx={{ margin: "0 20px" }}>
          <Typography variant="h7" sx={{ fontWeight: "bold", marginBottom: 2, fontFamily: 'Poppins', mb: 2 }}>
            {section}
          </Typography>
          {links.map((link) => (
            <Typography key={link.label}>
              <Link href={link.href} sx={{ color: "white", textDecoration: "none", fontFamily: 'Poppins', fontSize: '14px', mt: 1, 
                "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
               }}>
                {link.label}
              </Link>
            </Typography>
          ))}
        </Box>
      ))}

      {/* Stay Connected Section */}
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
        }}
      >
        <Box sx={{}}>
        <Typography>© 2025 Kauvery Hospital. All Rights Reserved.</Typography>
        <Typography >
          <Link href="/privacy-policy" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
           }}>
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link href="/terms-and-conditions" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
           }}>
            T&C
          </Link>
        </Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', ml: 8}}>
        <Typography variant="h6" sx={{ fontWeight: "bold"}}>
          Stay Connected
        </Typography>
        <Box>
          <IconButton href="https://facebook.com" target="_blank" sx={{ color: "white",
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://twitter.com" target="_blank" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://instagram.com" target="_blank" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://youtube.com" target="_blank" sx={{ color: "white",
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <YouTubeIcon />
          </IconButton>
        </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
